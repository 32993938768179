.msg-ctn {
  background: #fafafc;
  padding: 60px 0px;
  text-align: center;
  color: #1b0e44;
  .item {
    font-size: 60px;
    max-width: 920px;
    margin: 0 auto;
    padding: 0 30px;
    font-family: "adelle", serif;
    line-height: 1.2;
  }
  .triangle {
    padding-top: 10px;
    //font-size: 2vw;// ManhLT commnent because It causes error in Mobile view
  }
  .msg {
    letter-spacing: 1px;
    margin-top: 40px;
    background: #88829f;
    color: #fff;
    font-weight: bold;
    padding: 70px 30px;
    font-size: 54px;
    //font-size: 3vw;// ManhLT commnent because It causes error in Mobile view
  }
}

#scroll_dates {
  margin: 4% auto;
  width: 22px;
}
.gg-chevron-down {
  color: #ccc9d3;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(3);
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

@media (max-width: 550px) {
  .msg-ctn {
    .item {
      width: 85%;
      font-size: 36px;
      margin: 0 auto;
      padding: 0px;
    }
    .msg {
      padding: 25px 20px;
      font-size: 32px;
    }
  }
  .gg-chevron-down {
    transform: scale(2);
  }
}
