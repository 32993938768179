.sponsor-banner.container {
  padding: 0px;
  margin: 0 auto;
  .nav-link {
    padding: 0;
    display: flex;
    align-items: center;
  }
  .sponsor-container {
    padding: 0px;
    margin: 0px;
  }
  img {
    padding: 10%;
    width: 100%;
    flex-grow: 2;
  }
}
#about-us {
  padding-bottom: 50px;
  margin: 0px 4% 0px 0px;

  h1 {
    font-size: calc(1.5em + 1vw);
    font-family: "avenir_regular";
    color: rgba(25, 57, 70, 1);
    font-weight: bold;
  }

  p {
    color: rgba(25, 57, 70, 1);
  }

  a {
    font-weight: bold;
    text-decoration: underline;
    color: rgb(136, 130, 159);
  }

  a:hover {
    color: rgba(25, 57, 70, 1);
  }

  .download-button {
    a {
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline solid white;
      color: white;
    }
  }
}
