.home-top-email {
  .ctn-email-signup {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
      position: relative;
    }
    background: #17bebb;
    .container {
      position: relative;
      z-index: 20;
    }
    .email-signup-input {
      padding: 10px 0;
      .action_form {
        margin-bottom: 0;
      }
    }
    .close-padding {
      padding-right: 50px;
      padding-left: 50px;
    }
    .alert-responsive {
      display: flex;
      align-items: center;
    }
    .alert-email-title {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #fffaf7;
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif !important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      a {
        text-decoration: underline;
        color: inherit;
      }

      a:hover {
        text-decoration: none;
      }
    }
    .email-signup-input {
      .form-email {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .form-control {
        border: 1px solid #17bebb !important;
        border-radius: 0px !important;
        // height: 28px !important;
        line-height: 15px;
        font-size: 15px !important;
        letter-spacing: -0.02em;
        background: #ffffff;
        color: #17bebb;
        width: 100%;
        margin-bottom: 0px !important;
        &::placeholder {
          color: #17bebb !important;
        }
        &:-ms-input-placeholder {
          color: #17bebb !important;
        }
        &::-ms-input-placeholder {
          color: #17bebb !important;
        }
      }
      .warning-title {
        font-family: "garage-gothic", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        color: #193946;
        text-transform: uppercase;
        .icon-warning {
          width: 25px;
          margin-top: -5px;
        }
        margin-right: 10px;
      }
      .btn-send-email {
        font-family: "garage-gothic", sans-serif;
        font-size: 22px;
        font-weight: 700;
        margin-left: 10px;
        border: none;
        background: #ec704c !important;
        color: #193946;
        line-height: 30px;
        padding: 2px 12px;
        text-transform: uppercase;
        img {
          width: 22px;
          margin: 0px 5px 5px 10px;
        }
      }
    }

    .email-signup-line-split {
      width: 100%;
      height: 2px;
      background: #17bebb;
      padding: 0px 15px;
      margin: 0px 0px 15px;
    }
    .close-email-signup {
      cursor: inherit;
      position: absolute;
      right: 20px;
      top: 10px;
      z-index: 10000000;
    }
  }
}
.ctn-email-signup {
  // padding: 0px 0px 10px 0px;
  text-align: center;
  .email-signup-title {
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #193946;
    font-weight: 500;
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    margin-right: 10px;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      font-size: 15px !important;
      line-height: 16.5px !important;
      text-align: left;
    }
    &.language-customization-kr {
      font-size: 21px;
    }
  }
  // .mb-10{
  //   margin-bottom: 10px;
  // }
  .email-signup-input {
    .action_form {
      margin-bottom: 1rem;
      .ak-grid-row {
        flex: 1;
      }
    }
    .form-control {
      border: 1px solid #ec704c;
      border-radius: 0px !important;
      height: 36px;
      font-weight: normal;
      margin-bottom: 10px;
    }
    .btn-send-email {
      margin-left: 10px;
      border: none;
      background: transparent;
      border: none;
      background: #ec704c !important;
      line-height: 30px;
      padding: 2px 5px 2px 12px;
      color: #fff;
      text-transform: uppercase;
      img {
        width: 22px;
        margin: 0px 5px 5px 10px;
      }
    }
  }
  .display-none {
    display: none;
  }
}
@media (max-width: 768px) {
  .email-signup-title {
    font-size: 20px !important;
  }
  .register-to-vote {
    .btn-register {
      font-size: 56px !important;
      line-height: 46px !important;
    }
  }
  .ctn-email-signup {
    .email-signup-input {
      .form-control {
        width: 100%;
      }
      .action_form {
        display: inline-block;
        margin-bottom: 0;
      }
    }
    .btn-send-email {
      font-size: 15px;
    }
  }
  .home-top-email .ctn-email-signup {
    .alert-email-title {
      font-size: 14px;
      line-height: 18px;
      display: block;
      text-align: left;
      width: 90%;
    }
    .email-signup-input {
      .warning-title {
        font-size: 22px;
        line-height: 26px;
        display: block;
        .icon-warning {
          width: 20px;
        }
        margin-right: 5px;
      }
    }
  }
  .alert-responsive {
    display: flex;
    flex-direction: column;
  }
}
