.fade.modal.show {
  background-color: rgba($color: #1b0e44, $alpha: 0.95);
}

.alert-popup {
  .modal-content {
    border-radius: 0px;

    #contained-modal-title-vcenter {
      font-size: 24px;
      font-family: "avenir_bold", sans-serif;
      color: rgb(255, 255, 255);
      line-height: 26px;
      height: 26px;
      background-color: #f02d3a;
      padding: 2px 4px;
      width: fit-content;
    }

    .modal-header {
      border-bottom: none;

      button.close {
        span {
          display: block;
          height: 25px;
          width: 25px;
          color: black;
          border-radius: 50%;
          border: 1px solid black;
        }
      }
    }

    h2 {
      font-size: 32px;
    }
  }
}
