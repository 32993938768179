.ctn-about-us {
  .ctn-about-us-header {
    padding: 20px 0px;

    .ctn-about-us-title {
      text-align: center;
      font-weight: 700;
      color: #193946;
      font-size: 80px;
      line-height: 68px;
      text-transform: uppercase;
    }
  }

  .col-left {
    width: 50%;

    .image-vender {
      width: 30%;
      margin: 1%;
      text-align: center;
      display: inline-block;
    }
  }

  .col-right {
    width: 50%;
  }
  .collape-item {
    border-top: 5px solid #ec704c;
  }
  #contact {
    padding-top: 15px;
  }
  .text-content {
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #193946;
    margin-bottom: 20px;
    a {
      text-decoration: underline;
      color: #193946;
    }
    a:hover {
      text-decoration: none;
    }
  }
  .ctn-email-signup {
    .form-control {
      border: 1px solid #ec704c !important;
    }
  }
}

@media (max-width: 768px) {
  .ctn-about-us {
    .ctn-about-us-header {
      .ctn-about-us-title {
        font-size: 40px;
        line-height: 40px;
      }
    }
    .ctn-email-signup {
      .form-control {
        border: 1px solid #ec704c !important;
      }
    }
  }
}
