.ctn-check-your-voter {
  padding-right: 5px;
  .col-left img {
    width: 400px;
  }
}
.div-background {
  margin-bottom: 20px;
  position: relative;
  .div-below {
    border: 1px solid #261a4d;
    height: 415px;
    position: relative;
    left: 10px;
    box-shadow: 2px 3px #00000026;
    .dog-ear-below {
      position: absolute;
      bottom: -27px;
      right: -23px;
      transform: rotate(-29deg);
      width: 122px;
      height: 60px;
      border-top: 1px solid #261a4d;
      background: #fafafc;
      box-shadow: inset 0 3px #00000026;
    }

    .div-above {
      position: absolute;
      border: 1px solid #261a4d;
      height: 415px;
      background: #f02d3a;
      width: 100%;
      top: -14px;
      left: -14px;
      padding: 50px 50px;
      color: #fff;
      display: flex;
      align-items: center;
      .step-number {
        font-family: "marydale", sans-serif;
        font-weight: bolder;
        font-size: 36px;
        line-height: 1;
        padding-right: 10px;
        width: 15%;
        text-align: right;
      }
      .step {
        display: flex;
        flex-wrap: wrap;
        justify-self: center;
        justify-content: center;
        a {
          width: 85%;
          margin-left: auto;
        }
      }
      .stepInfo {
        width: 85%;
      }
      .title {
        font-size: 36px;
        font-weight: bold;
      }
      .content {
        font-size: 24px;
        margin: 10px 0px;
        width: 85%;
        margin-left: auto;
      }
      .btn-check-voter {
        font-size: 24px;
        color: #1b0e44;
        background: #fff;
        font-weight: bold;
        border-radius: 0;
        //font-size: 1.1vw;  // ManhLT commnent because It causes error in Mobile view
      }
      .dog-ear-above {
        position: absolute;
        bottom: -18px;
        right: -21px;
        transform: rotate(-30deg);
        width: 114px;
        height: 50px;
        border-top: 1px solid #261a4d;
        background: #fafafc;
      }

      .triangle {
        border-top: 60px solid transparent;
        border-right: 40px solid #261a4d;
        border-bottom: 55px solid transparent;
        position: absolute;
        bottom: -12px;
        right: 39px;
        transform: rotate(60deg);
      }

      .triangle::after {
        content: "";
        border-top: 58px solid transparent;
        border-right: 38px solid #f25a64;
        border-bottom: 53px solid transparent;
        position: absolute;
        bottom: -53px;
        left: 2px;
      }
    }
  }
}
@media (max-width: 550px) {
  .ctn-check-your-voter {
    .col-left {
      display: none;
    }
  }
  .div-background {
    padding: 0px 5%;
    .div-below {
      height: 280px !important;
      .dog-ear-below {
        bottom: -26px;
        right: -19px;
        width: 93px;
        height: 52px;
      }
      .div-above {
        height: 280px !important;
        padding: 0px 20px 20px 20px;
        .step {
          justify-content: space-between;
        }
        .step-number {
          font-family: "marydale", sans-serif;
          font-size: 20px;
          width: 20px;
          height: 28px;
        }
        .title {
          font-size: 24px;
        }
        .content {
          font-size: 18px;
          // width: 100%;
        }
        .btn-check-voter {
          font-size: 18px;
        }
        .dog-ear-above {
          bottom: -26px;
          right: -19px;
          width: 81px;
          height: 50px;
        }

        .triangle {
          border-top: 52px solid transparent;
          border-right: 30px solid #261a4d;
          border-bottom: 31px solid transparent;
          position: absolute;
          bottom: -8px;
          right: 27px;
          transform: rotate(60deg);
        }

        .triangle::after {
          border-top: 50px solid transparent;
          border-right: 27px solid #f25a64;
          border-bottom: 26px solid transparent;
          position: absolute;
          bottom: -26px;
          left: 3px;
        }
      }
    }
  }
}

@media (max-width: 998px) {
  .ctn-check-your-voter {
    .col-left img {
      display: block;
      margin: auto;
      margin-bottom: 35px;
    }
  }
}

@media (max-width: 1200px) {
  .div-background {
    .div-below {
      height: 500px;
      .div-above {
        height: 500px;
      }
    }
  }
}
