@import "bootstrap";

.header-container {
  margin-top: 15px;
  position: sticky;
  top: 0px;
  background: #fffaf7;
  z-index: 1000;
  a:hover {
    color: #17bebb;
    text-decoration: none;
  }
  .header-content {
    box-shadow: inset 0px 0px 0px 1px #ec704c;
    margin: 0px 38px;
    padding: 0px 15px;
    align-items: center;
    position: relative;
  }
  h4 {
    margin-bottom: 0px;
  }

  .icon-star {
    margin: 10px;
  }
  .header-info {
    text-transform: uppercase;
    word-spacing: 5px;
    padding: 15px 0px;
  }
  .header-learn-more {
    text-transform: uppercase;
    word-spacing: 5px;
    padding: 10px 0px;
    text-align: right;
    cursor: inherit;
    h4 {
      font-size: 30px;
      line-height: 36px;
      letter-spacing: 0.05em;
      float: right;
      text-decoration: none;
      color: #193946;
    }
    h4:hover {
      color: #17bebb;
      text-decoration: underline;
    }
  }
  .header-title {
    text-align: center;
    text-transform: uppercase;

    h4 {
      font-weight: 700;
      font-size: 30px;
      line-height: 30px;
      a {
        font-family: "garage-gothic", sans-serif;
        font-style: normal;
        text-align: center;
        text-transform: uppercase;
        color: #193946;
        vertical-align: middle;
      }
      a:hover {
        text-decoration: none;
      }
    }

    .fa-star {
      margin: 0px 20px;
    }
    .logo-header {
      width: 300px;
    }
  }

  .header-social {
    text-align: right;
  }

  .ctn-header-nav {
    border: 1px solid #ec704c;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 59px;
    background: #fffaf7;
    text-align: left;
    padding: 20px 30px 5%;
    .col-header {
      display: flex;
      flex-direction: column;
    }
    // .title:hover {
    // 	color: #17bebb;
    // }
    .title {
      font-size: 30px;
      line-height: 30px;
      color: #193946;
      text-transform: uppercase;
      font-weight: 700;
      margin: 12px 0px;
    }
    .sub-content {
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-size: 20px;
      font-weight: 500;
      color: #193946;
      text-transform: initial;
      margin-bottom: 5px;
      line-height: 25px;
      letter-spacing: -0.02em;
    }
    .sub-content:hover {
      color: #17bebb;
      text-decoration: none;
    }
    .btn-close {
      cursor: inherit;
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 1;
    }
  }
}

@media (max-width: 550px) {
  .header-container {
    p {
      font-size: 20px;
    }

    .ctn-header-nav .col-header {
      .title {
        font-size: 20px;
        line-height: 20px;
      }
      .sub-content {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0px;
      }
    }
  }
  .header-container .header-content {
    padding: 0 15px;
    margin: 0px 15px;
  }
  .header-container .header-title h4 {
    font-size: 24px;
    font-weight: 400;
  }
  .header-container .ctn-header-nav {
    top: 49px;
  }
}

@media (max-width: 991px) {
  .header-container {
    // padding: 15px 30px;

    // .header-info,
    .header-social,
    .header-learn-more h4 {
      display: none;
    }

    .header-title {
      // flex: 0 0 83.333333% !important;
      // max-width: 83.333333% !important;

      .fa-star {
        display: none;
      }

      .logo-header {
        width: 80%;
        max-width: 300px;
      }
    }
  }

  .menu-container {
    top: -10px;
    right: -25px;

    .menu-bg {
      background-color: transparent !important;
    }

    .bm-menu-wrap {
      width: 100% !important;
      top: 0px;
    }
  }
}

.alert-voter.alert {
  background-color: #f02d3a;
  padding: 5px;
  border-radius: 0px;
  margin-bottom: 0;

  button.close {
    color: #ffffff;
    opacity: 1;
  }

  .alert-container {
    background-color: rgb(240, 45, 58);

    p {
      margin: 5px auto;
      font-size: calc(0.7em + 0.3vw);
      font-family: "avenir_regular", sans-serif;
      color: rgb(255, 255, 255);
      text-align: center;
      font-style: normal;
      padding: 0;
    }

    .alert-title-bold {
      font-family: "avenir_bold", sans-serif;
    }

    .alert-button {
      color: rgb(255, 255, 255);
      text-decoration: none;
      border-radius: 0;
    }

    $mynewcolor: #1b0e44;

    .btn-headeralert {
      @include button-variant(
        $mynewcolor,
        darken($mynewcolor, 7.5%),
        darken($mynewcolor, 10%),
        lighten($mynewcolor, 5%),
        lighten($mynewcolor, 10%),
        darken($mynewcolor, 30%)
      );
    }

    .btn-outline-headeralert {
      @include button-outline-variant(
        $mynewcolor,
        #222222,
        lighten($mynewcolor, 5%),
        $mynewcolor
      );
    }

    .link {
      color: #fff;
      text-decoration: none;
    }

    .dismiss-button {
      color: white;
    }

    $mynewcolor2: #f02d3a;

    .btn-outline-dismissbutton {
      @include button-outline-variant(
        $mynewcolor2,
        #222222,
        lighten($mynewcolor2, 5%),
        $mynewcolor2
      );
    }
  }
}
