.info-hub-container {
  h1 {
    font-size: calc(2.5em + 1vw);
    font-family: "avenir_regular";
    color: rgba(25, 57, 70, 1);
  }

  h2 {
    font-size: calc(1.3em + 1vw);
    font-family: "avenir_demi";
    color: rgba(25, 57, 70, 1);
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 18px;
    font-family: "avenir_bold";
    color: rgba(25, 57, 70, 1);
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
  }

  p {
    a {
      font-weight: bold;
      text-decoration: underline;
      color: rgb(136, 130, 159);
    }

    a:hover {
      color: rgba(25, 57, 70, 1);
    }
  }

  section {
    padding: 35px 0 0;
  }

  .featured-race-container {
    padding: 20px;
    color: white;
    margin-bottom: 1rem;

    h2 {
      color: white;
    }

    ol {
      padding-left: 30px;
      margin-bottom: 0;

      li {
        padding: 2px;
      }

      li::marker {
        font-weight: bold;
      }
    }

    a {
      color: white;
      font-weight: bold;
      text-decoration: underline;
    }

    a:hover {
      color: #e7e7e7;
    }
  }

  .envelope-container {
    padding-left: 25px;

    h3 {
      text-transform: unset;
      text-align: center;
      margin: 10px;
    }

    h4 {
      font-size: 12px;
      font-family: "avenir_bold";
      color: rgba(25, 57, 70, 1);
      font-weight: bold;
      text-align: center;
      margin-top: -10px;
    }
  }

  .navigations-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .download-button {
      min-width: 0;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .alert-box-container {
    background-color: #88829f;
    color: white;
    padding: 25px 0;
    margin-top: 30px;

    h2 {
      color: white;
      margin: 15px 0;
    }

    h4 {
      font-size: 24px;
      font-family: "avenir_bold";
      color: rgb(240, 45, 58);
      line-height: 1.2;
      background-color: white;
      width: fit-content;
      padding: 0 4px;
      line-height: 26px;
      height: 26px;
      padding: 2px 4px;
      width: fit-content;
    }

    .download-button {
      min-width: 0;
    }

    .social-spacing {
      text-align: center;
      padding: 10px 0;
    }

    .social-text {
      font-size: calc(1em + 1vw);
      font-family: "avenir_bold";
      color: rgb(255, 255, 255);
      font-weight: bold;
      line-height: 1.2;
      // margin-top: 30px;
    }

    .icon-bg {
      margin: 0 5px;
    }
  }
}

@media (max-width: 568px) {
  .info-hub-container {
    .navigations-container {
      margin-top: -40px;
      .download-button {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
