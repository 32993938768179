.info-hub-container {
  .download-button {
    min-width: 420px;
  }
  section {
    margin: 30px 0;
  }
  h1 {
    font-family: "avenir_regular";
    color: rgba(25, 57, 70, 1);
    line-height: 1.2;
  }
}
.email-bg-container {
  background-color: #88829f;
  margin: 50px auto -90px auto;
  position: relative;
  z-index: 2;
}
.sample-text {
  font-family: "garage-gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 30px;
  text-align: center;
  color: #193946;
  padding: 30px 0;
  &.language-customization-kr {
    font-size: 36px;
  }
}
.download-button {
  background-color: #f02d3a !important;
  border: none;
  color: white;
  padding: 10px 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
  border-radius: 0 !important;
}

.back-button {
  font-family: "garage-gothic", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 34px;
  text-transform: uppercase;
  color: #193946;
  outline: none;
  border: none;
  background: transparent;
}

.back-button:hover {
  color: #ec704c;
}

h1 {
  font-family: "garage-gothic", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 147px;
  line-height: 125px;
  color: #193946;
  text-transform: uppercase;
}

h2 {
  font-family: "garage-gothic", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 68px;
  color: #193946;
  text-transform: uppercase;
}

h3 {
  font-family: "garage-gothic", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 68px;
  color: #193946;
}

h4 {
  font-family: "garage-gothic", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 34px;
  text-transform: uppercase;
  color: #193946;
}

h5 {
  font-family: "garage-gothic", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  color: #193946;
}

p {
  font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-style: normal;
  color: #193946;
  a {
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-style: normal;
    color: #193946;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
    color: #193946;
  }
}

.home-title {
  font-family: "garage-gothic", sans-serif;
  font-size: 14vw;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  line-height: 12vw;
  letter-spacing: -0.02em;
  &.language-customization-kr {
    font-size: 100px;
    line-height: 100px;
  }
}
.ctn-learn-more {
  .home-title {
    font-family: "garage-gothic", sans-serif;
    font-weight: 700;
    font-size: 80px;
    line-height: 68px;
    padding: 20px 0px;
    max-width: 945px;
    margin: 0 auto;
  }
}

.home-leader {
  font-size: 15.5vw;
  text-transform: uppercase;
  text-align: center;
  line-height: 12.5vw;
  letter-spacing: -0.02em;
  &.language-customization-kr {
    font-size: 100px;
    line-height: 100px;
  }
  &.language-customization-es {
    font-size: 14vw;
    line-height: 12vw;
  }
}
.page-not-found {
  .title {
    font-family: "garage-gothic", sans-serif;
    font-size: 200px;
    font-weight: 800;
    text-transform: uppercase;
  }
  .leader {
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 40px;
  }
  .btn-redirect {
    font-family: "garage-gothic", sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 31px;
    padding: 10px;
    letter-spacing: -0.02em;
    background-color: #ec704c;
    color: #193946;
    text-transform: uppercase;
    border: none;
    margin-top: 30px;
    margin: 10px;
    min-width: 250px;
    a {
      color: #193946;
      text-decoration: none;
    }
    &:hover {
      background-color: #17bebb;
    }
  }
}

.additional-resources {
  margin: 40px 0 0px;

}

a.questionnaire-submit {
  font-family: "garage-gothic", sans-serif;
  font-weight: 700;
  color: #fff;
  line-height: 34px;
  font-size: 40px;
  background-color: #ec704c;
  text-transform: uppercase;
  width: auto;
  padding: 5px 26px;
  border: none;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #17bebb;
  }
  img {
    height: 25px;
    margin: 0px 0px 0px 10px;
  }
}
a.questionnaire-submit:hover {
  color: #fff;
  text-decoration: none;
}
@media (max-width: 1024px) {
  .ctn-learn-more {
    .home-title {
      font-size: 60px;
      line-height: 54px;
    }
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 40px;
    line-height: 34px;
  }

  h3 {
    font-size: 40px;
    line-height: 34px;
  }

  h4 {
    font-size: 25px;
    line-height: 21px;
  }

  h5 {
    font-size: 20px;
    line-height: 24px;
  }

  .home-title {
    font-size: 14.5vw;
    line-height: 12vw;
    &.language-customization-kr {
      font-size: 12.5vw;
      line-height: 11vw;
    }
  }

  .home-leader {
    font-size: 17vw;
    line-height: 15vw;
    &.language-customization-kr {
      font-size: 14vw;
      line-height: 13vw;
    }
    &.language-customization-es {
      font-size: 14vw;
      line-height: 13vw;
    }
  }
  .ctn-line-split {
    height: 8px;
  }
  .back-button {
    margin: 15px 0;
    font-size: 16px;
  }
  .ctn-email-signup {
    display: none;
  }
  .ctn-learn-more {
    .home-title {
      font-size: 40px;
      line-height: 34px;
      padding: 20px 5%;
    }
  }
  .sample-text {
    font-size: 20px;
    line-height: 20px;
    padding: 0;
  }
  .mobile-hide {
    display: none;
  }
  .mobile-text-center {
    text-align: center;
  }

  a.questionnaire-submit {
    font-size: 24px;
    line-height: 20px;
    img {
      height: 17px;
      width: 23px;
      margin: 0px 0px 0px 10px;
    }
  }
}

@media (max-width: 568px) {
  .info-hub-container {
    .download-button {
      min-width: unset;
      width: 100%;
    }
  }
}

@media (min-width: 1690px) {
  .home-title {
    font-size: 242px;
    line-height: 200px;
    &.language-customization-kr {
      font-size: 100px;
      line-height: 100px;
    }
  }

  .home-leader {
    font-size: 290px;
    line-height: 250px;
    &.language-customization-kr {
      font-size: 100px;
      line-height: 100px;
    }
    &.language-customization-es {
      font-size: 220px;
      line-height: 185px;
    }
  }
}
