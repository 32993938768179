.desktop-nav {
  background-color: rgb(238, 240, 246);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);

  .link-nav {
    font-size: 14px;
    font-family: "avenir_bold";
    color: rgba(25, 57, 70, 1);
    text-transform: uppercase;
    padding: 5px 25px;
  }

  .link-nav:focus,
  .link-nav:active {
    outline: none;
    box-shadow: none;
  }

  .info-link {
    font-size: 14px;
    font-family: "avenir_bold";
    color: rgb(255, 255, 255);
    font-weight: bold;
    text-transform: uppercase;
    background-color: rgb(240, 45, 58);
    border-radius: 0;
  }
}
