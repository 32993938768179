.signup-form {
  input,
  textarea {
    padding: 12px;
    border-radius: 0px !important;
    width: 100%;
    margin: 3px 0;
    border: none;
  }

  .submit-button {
    background-color: #1b0e44;
    border: none;
    color: white;
    padding: 7px 1px;
    text-align: center;
    text-decoration: none;
    width: 100%;
    margin: 3px 0;
  }

  .form-col {
    padding: 0 4px;
  }

  .form-check {
    color: white;
    font-size: 18px;
    font-family: "avenir_regular";
    padding: 0;
    input {
      width: fit-content;
      padding: 0;
      margin-right: 10px;
      position: initial;
    }

    label {
      display: contents;
    }
  }
}

.collection-background {
  padding: 50px 0px;
  color: rgb(255, 255, 255);
  h2 {
    color: rgb(255, 255, 255);
  }
  .collection-text {
    font-size: calc(1.5em + 1vw);
    font-family: "avenir_regular";
    color: rgb(255, 255, 255);
    font-weight: bold;
    margin-bottom: 35px;
  }
}

.show-links {
  text-align: center;

  .collection-text {
    margin-bottom: 20px;
  }
  p {
    color: white;
    margin-bottom: 0;
    a {
      font-weight: bold;
      text-decoration: underline;
      color: white;
    }

    a:hover {
      color: rgba(25, 57, 70, 1);
    }
  }
}

.secondName {
  display: none;
}
