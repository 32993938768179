.featured-race-container {
  background-color: rgb(136, 130, 159);
  padding: 50px 0;

  h2 {
    font-size: calc(1.5em + 1vw);
    font-family: "avenir_regular", sans-serif;
    color: rgb(255, 255, 255);
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 25px;
  }

  p {
    color: white;
  }
}

.featured-race-bg {
  padding: 0;
}
