.ballot-container {
  margin: 20px 0 40px 0;
  border: 10px solid #ec704c;

  .title-background {
    padding: 30px 5px 0 5px;

    a {
      color: #17bebb;
      width: 100%;
    }
  }

  .title {
    font-weight: 700;
    font-size: 80px;
    line-height: 68px;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 10px solid #ec704c;
    padding: 10px 0px 20px 0px;
    color: #193946;
  }

  .recommendation-text {
    font-size: 18px;
    font-family: "avenir_regular", sans-serif;
    color: rgba(25, 57, 70, 1);
    line-height: 1.2;
    text-align: center;
    margin: 10px auto 15px auto;
    width: 100%;
    display: inline-block;

    span {
      font-family: "avenir_bold", sans-serif;
      color: #f02d3a;
      display: inline-block;
    }
  }

  a:hover {
    text-decoration: underline;
    text-decoration-color: #17bebb;
  }

  .candidate {
    flex-grow: 2;
    padding: 0!important;
    max-width: none;
  }

  .name {
    text-transform: uppercase;
  }

  .tab-manager {
    justify-content: center;
    margin: auto;
    width: 100%;
    padding-top: 20px;
    flex-wrap: wrap;
    column-gap: 20px;
    padding-bottom: 20px;
    border-bottom: 4px solid #ec704c;
    @media (max-width: 992px) {
      column-gap: 0;
    }
  }
  .tab-manager-dropdown {
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 10px auto;
    width: 100%;
    padding-top: 20px;
  }

  .main-tab {
    margin: 5px;
    width: fit-content;
    min-width: 100px;
    flex: 16%;

    @media (max-width: 992px) {
      flex: 48%;
    }

    a {
      font-family: "garage-gothic", sans-serif;
      font-size: 40px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.02em;
      text-align: center;
      background-color: #ec704c;
      color: #193946;
      text-transform: uppercase;
      border: none;
      outline: none;
      text-align: center;
    }

    .active-tab {
      background-color: #17bebb;
    }

    .active-tab:hover {
      background-color: #17bebb;
    }

    a:hover {
      background-color: #17bebb;
      text-decoration: none;
    }

    a.nav-link.disabled {
      color: #ccc;
    }
  }

  .issue-nav-container {
    padding: 0px 0px 43px 0px;
  }

  .issue-nav-container-min {
    padding: 0px;
  }

  .issue-tab-text {
    font-family: "garage-gothic", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 34px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #0f2f3c;
    margin-right: 10px;
  }

  .issues-tab {
    a {
      font-size: 14px;
      font-family: "avenir_bold";
      color: #8882a1;
      font-weight: bold;
      text-decoration: underline;
      text-transform: uppercase;
    }

    a:hover {
      color: rgba(27, 14, 68, 0.8);
      text-decoration: underline;
    }
  }

  .issues-tab-active {
    a {
      color: rgba(25, 57, 70, 1);
      text-decoration: underline;
    }
  }
  .Dropdown-root {
    position: relative;
    font-family: "garage-gothic", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    background: #fffaf7;
    border: 2px solid #193946;
  }

  .Dropdown-control {
    position: relative;
    overflow: hidden;
    border-radius: 0;
    cursor: inherit;
    outline: none;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease;
    min-width: 250px;
  }

  .Dropdown-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  .Dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    display: block;
    height: 0;
    margin-top: ceil(-2.5);
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
  }

  .is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }

  .Dropdown-menu {
    background: #fffaf7;
    border: 2px solid #193946;
    border-top: none;
    margin-top: -1px;
    margin-left: -2px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    min-width: 254px;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }

  .Dropdown-menu .Dropdown-group > .Dropdown-title {
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
  }

  .Dropdown-option {
    box-sizing: border-box;
    cursor: inherit;
    display: block;
    padding: 2px 5px;
  }

  .Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .Dropdown-option:hover {
    color: #ec704c;
  }

  .Dropdown-option.is-selected {
    color: #ec704c;
  }

  .Dropdown-noresults {
    box-sizing: border-box;
    color: #193946;
    cursor: inherit;
    display: block;
    padding: 8px 10px;
  }
  img.icon-drop-down {
    width: 20px;
    float: right;
    position: absolute;
    right: 10px;
    top: 20px;
  }
  img.icon-drop-down.open {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
  }
  .amendment-container {
    margin-bottom: 20px;
  }
  .candidate-container {
    padding: 30px 40px 10px;
    .candidate-row {
      border-bottom: 2px solid #88829f;
      padding-top: 20px;
    }

    h2 {
      font-size: calc(0.9em + 1vw);
      font-family: "avenir_bold", sans-serif;
      color: rgba(25, 57, 70, 1);
      font-weight: bold;
      line-height: 1.2;
      text-align: center;
    }

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 34px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #0f2f3c;
      @media (max-width: 768px) {
        font-size: 25px;
        line-height: 21.25px;
      }
    }

    .amendment-tittle {
      text-align: left;
      padding: 0 0 35px;
      // font-size: calc(0.5em + 1vw);
    }

    h4 {
      font-size: 14px;
      font-family: "avenir_demi", sans-serif;
      color: rgb(240, 45, 58);
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.2;
    }

    p {
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      letter-spacing: -0.02em;
      color: #193946;
      text-align: left;
    }

    .thumb-container {
      text-align: left;
      margin-bottom: 70px;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .grid-container {
      .col-3 {
        padding-right: 5px;
        padding-left: 5px;
      }

      h2 {
        color: rgba(25, 57, 70, 1);
        text-align: left;
        flex-grow: 2;
      }

      h3 {
        font-size: 12px;
        text-align: center;
      }

      .thumb-container {
        text-align: center;
        margin-bottom: 10px;

        img {
          max-width: 100px;
        }
      }

      .matrix-row {
        background-color: #eef0f6;
        margin-top: 10px;
        margin-bottom: 10px;
        align-items: center;

        .matrix-title {
          font-size: 24px;
          font-family: adelle, serif;
          color: rgba(25, 57, 70, 1);
          line-height: 1.2;
          text-align: left;
          margin-top: 10px;
          margin-bottom: 0;
        }

        .matrix-descriptor {
          font-size: 15px;
        }
        .matrix-check,
        .matrix-x {
          text-align: center;
          padding: 10px 0;
          img {
            height: 50px;
            width: auto;
          }
        }
        .matrix-check {
          background-color: #49397a;
        }

        .matrix-x {
          background-color: #ccc9d4;
        }
      }
      .issues-dropdown {
        width: 80%;
        border-radius: 0;
      }
    }
    .candidate-select {
      float: right;
      .user-checked {
        font-size: 15px;
        font-weight: 500;
        line-height: 22.5px;
        font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        letter-spacing: -0.02em;
        text-align: left;
        position: relative;
        cursor: inherit;
        .user-checked-title {
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 30px;
          background: #ec704c;
          padding: 8px 20px;
          float: left;
          text-transform: uppercase;
          color: #fff;
          font-family: "garage-gothic", sans-serif;
          min-width: 185px;
          text-align: center;
        }
        .user-checked-checkbox {
          float: right;
          margin: 0px 0px 0px 10px;
        }

        .user-checked-checkbox img {
          width: 46px;
        }
        img {
          width: 43px;
        }

        .icon-user-unchecked {
          z-index: 10;
        }
        .selected {
          background-color: #abe188;
          color: #193946;
        }
        .unselect {
          background-color: #595959;
          color: #fff;
          // cursor: not-allowed;
        }
        .icon-user-checked {
          position: absolute;
          z-index: 1;
          top: 5px;
          right: 0px;
        }
      }
      .is-disabled {
        .icon-user-unchecked {
          filter: grayscale(100%);
          // cursor: not-allowed;
        }
      }
    }
    .voting-stance {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 30px;
      background-color: #595959;
      padding: 8px 10px 8px 38px;
      float: left;
      text-transform: uppercase;
      color: #fff;
      font-family: "garage-gothic", sans-serif;
      width: 164px;
      text-align: center;
      position: relative;
    }
    .voting-stance-icon {
      position: absolute;
      top: 13px;
      left: 13px;
      width: 16px;
    }
    .bills .hidden {
      display: none !important;
    }
    .measures-select {
      flex-direction: column;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .candidate-select {
        margin: 5px 0;
      }
    }
    .content-social {
      .icon-social{
        margin: 10px 7px 10px 0px;
      }
      p {
        margin-bottom: 0px;
      }
    }
    .content-details {
      .bill-display {
        flex-direction: row;
        margin-bottom: 40px;
      }
      p.bill-details {
        margin-top: 0px;
      }
    }
    .content-details {
      p {
        font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 30px;
        letter-spacing: -0.02em;
        color: #193946;
        text-align: left;
        margin-top: 5px;
        a {
          color: #193946;
          text-decoration: underline;
        }
        a:hover {
          text-decoration: none;
        }
      }
      .campaign-website{
        color:#17BEBB;
      }
    }
  }
  .is-selected-candidate {
    background: rgba(171, 225, 136, 0.15);
  }

  .see-more {
    text-align: center;
    margin: 20px auto;

    .see-more-link {
      text-align: center;
      font-size: 14px;
      font-family: "avenir_regular";
      color: rgb(136, 130, 159);
      text-decoration: underline;
      text-transform: uppercase;
    }

    .see-more-link:hover {
      color: rgba(25, 57, 70, 1);
    }

    p {
      font-style: italic;
      font-size: 14px;
      font-family: "avenir_regular";
      margin-top: 10px;
    }
  }
  .office-description {
    padding: 0px 20px;
    strong {
      font-family: "garage-gothic", sans-serif;
      font-weight: 700;
      font-size: 40px;
      letter-spacing: -0.02em;
    }
  }

  .voting-history-source {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      display: flex;
      align-items: center;
    }
  }
  .voting-history-source-desktop {
    padding: 0px 40px 30px;
  }
  .voting-history-source-mobile {
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: center;
    padding: 0px 15px 15px;
    span {
    }
    img {
      width: 127px;
    }
  }

  .zero-margin {
    margin: 0px;
  }
}

@media (max-width: 550px) {
  .ctn-line-split {
    height: 4px !important;
  }
  .ballot-container {
    border-width: 3px;
    .title {
      border-width: 4px;
    }
    .tab-manager {
      padding-top: 20px;
      padding-bottom: 0;
      border-bottom: 0;
      .main-tab {
        width: 100%;
        a {
          font-size: 20px;
          text-align: center;
          line-height: 20px;
          padding: 7px 40px;
          margin: 0px 10px 0px 0px;
        }
      }
    }
    .tab-manager-dropdown {
      padding-bottom: 0;
    }
    .icon-social{
      width: 20px;
      margin-right: 7px;
    }
  }
  .sup {
    cursor: inherit;
  }
}

@media (max-width: 1230px) {
  .ballot-container {
    .main-tab {
      a {
        font-size: 28px;
        line-height: 28px;
      }
    }
  }
}

@media (max-width: 992px) {
  .ballot-container {
    .candidate-container {
      .candidate-select {
      float: left;
      }
    }
    .amendment-container {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 768px) {
  .ballot-container {
    border-width: 3px;
    .title-background {
      padding: 20px 5px;
    }
    .main-tab {
      width: 260px;
      text-align: center;
    }
    .issue-nav-container {
      padding-bottom: 0px;
      border-bottom: none;
      .issue-tab-text {
        font-size: 20px;
      }
      .issues-dropdown {
        font-size: 20px;
        line-height: 20px;
        .icon-drop-down {
          top: 12px;
        }
      }
    }
    .title {
      border-width: 4px;
      font-size: 40px;
      line-height: 40px;
    }
    .tab-manager {
      padding-top: 20px;
      border-bottom: none;
      .main-tab {
        width: 100%;
        a {
          font-size: 20px;
          text-align: center;
          line-height: 20px;
          padding: 7px 40px;
          margin: 0px 10px 0px 0px;
        }
      }
    }
    .candidate-mobile.amendment-container {
      padding: 0px 0 20px 0;
    }
    .candidate-mobile {
      padding: 20px 0;
      h3 {
        text-align: left !important;
      }
      .content-details {
        .bill-display {
          flex-direction: column;
          margin-bottom: 8px;
        }
        p.bill-details {
          margin-top: 10px;
        }
        p {
          font-size: 15px;
          line-height: 18px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
      .candidate-select {
        float: left;
        .user-checked {
          display: flex;
          flex-direction: row-reverse;

          .user-checked-title {
            font-size: 15px;
            line-height: 15px;
            padding: 5px 5px;
            min-width: 90px;
          }
          img {
            width: 23px;
          }
          .user-checked-checkbox {
            margin: 0px 0px 0px 5px;

            img {
              height: 25px;
              width: auto;
            }
          }
        }

      }
      .voting-stance {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 13px;
        background-color: #595959;
        padding: 8px 10px 8px 38px;
        float: left;
        text-transform: uppercase;
        color: #fff;
        font-family: "garage-gothic", sans-serif;
        width: auto;
        text-align: center;
        position: relative;
      }
      .voting-stance-icon {
        position: absolute;
        top: 8px;
        left: 13px;
        width: 11px;
      }
      .measures-select {
        padding-bottom: 10px;
        flex-direction: row;
        justify-content: space-between; 
        gap: 15px;
        .candidate-select {
          flex-grow: 1;
          .user-checked-title {
            flex-grow: 1;
          }
        }
      }
    }
    .candidate-container {
      .thumb-container {
        text-align: center;
        margin-bottom: 0;
      }

      h3 {
        text-align: center;
      }

      .grid-container {
        .thumb-container {
          img {
            max-width: 100%;
          }
        }

        .matrix-row {
          .matrix-title {
            font-size: 16px;
          }
        }
      }
      .amendment-tittle {
        padding: 0;
      }
      p {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 20px;
      }
    }
    .office-description {
      strong {
        font-size: 25px;
      }
    }
    .icon-social{
      width: 20px;
      margin-right: 7px;
    }
    .candidate {
      flex-grow: 2;
    }
  }
}
@media (max-width: 991px) {
  .ballot-container {
    .main-tab {
      width: 260px;
      text-align: center;
    }
    .icon-social{
      width: 20px;
      margin-right: 7px;
    }
  }
}
