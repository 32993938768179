form#create_personalized {
  button.button-submit:disabled {
    background: #ec714b;
    opacity: 1;
  }

  button.button-submit:active,
  button.button-submit:focus {
    outline: none;
    box-shadow: none;
    background: #17bebb;
  }

  .alert-warning {
    border: none;
    border-radius: 0;
    background: #17bebb;
    color: #193946;
    font-family: "garage-gothic", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-warning {
      width: 25px;
      margin: 0 5px;
    }
  }
}

@media (max-width: 991px) {
  form#create_personalized {
    button.button-submit img {
      width: 30px;
    }
  }
}
