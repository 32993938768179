.ctn-quiz {
  .quiz-title {
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 25px;
    line-height: 27.5px;
    font-weight: normal;
    letter-spacing: -0.02em;
    text-align: center;
    margin: 5% auto 0%;
    max-width: 800px;
  }

  // padding: 0px 10px;

  .quiz-content {
    display: flex;
    text-align: center;
    justify-content: center;

    .quiz-item {
      position: relative;
      width: 33%;
      margin: 60px 0px;
      border-left: 5px solid #17bebb;
      .quiz-badge {
        position: absolute;
        width: 40%;
        max-width: 172px;
        top: -50px;
        left: 15px;
      }

      .quiz-item-img {
        max-width: 80%;
      }

      .quiz-item-title {
        font-size: 40px;
        font-weight: 700;
        line-height: 34px;
        padding: 20px 15%;
        letter-spacing: -0.02em;
      }

      .quiz-item-action {
        padding: 10px 15%;

        .btn-action {
          float: right;
          font-size: 40px;
          line-height: 34px;
          font-weight: 700;
          letter-spacing: -0.02em;
          font-family: "garage-gothic", sans-serif;
          background-color: #ec704c;
          color: rgba(25, 57, 70, 1);
          text-transform: uppercase;
          padding: 2% 10%;
          outline: none;
          border: none;

          &:focus {
            outline: 0;
          }
        }

        .btn-action:hover:not([disabled]) {
          background: #17bebb;
        }

        .btn-action.btn-myth:hover:not([disabled]) {
          background: #fe4a49;
        }

        .btn-active {
          // border: 3px solid #EC704C;
          background: #17bebb;
        }

        .btn-active.btn-myth {
          // border: 3px solid #EC704C;
          background: #fe4a49;
        }

        .btn-action:first-child {
          text-transform: uppercase;
          float: left;
        }
      }

      .quiz-answer {
        clear: both;
        // padding: 20px 10%;
        font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        font-size: 25px;
        color: #193946;
        line-height: 27.5px;
        font-weight: 500;
        letter-spacing: -0.02em;
        .show-quiz {
          padding: 20px;
        }
      }
    }

    .quiz-item:first-child {
      border-left: none;
    }

    .quiz-social {
      font-size: 30px;
      button {
        margin: 0px 2%;
      }
    }
  }

  .quiz-start-over {
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 25px;
    line-height: 27.5px;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: #193946;
    text-align: center;
    border-top: 5px solid #17bebb;
    width: 92%;
    margin: 10px auto 0px;
    padding: 30px 0px 15px;
    cursor: inherit;
    .btn-startover {
      margin-left: 1%;
    }
    svg {
      margin-left: 20px;
    }
  }
  .carousel-wrapper {
    .quiz-item {
      width: 100%;
    }
    .control-dots {
      bottom: -15px;

      .dot {
        box-shadow: none;
        width: 16px;
        height: 16px;
        border: 2px solid #193946;
        opacity: 1;
      }
      .selected {
        background: #ec704c;
      }
    }
    .control-prev {
      background: url("./arrow-left.png") no-repeat !important;
      //background: url("/public/images/arrow-left.png");
      // bottom: -5px !important;
      position: absolute !important;
      top: auto !important;
      opacity: 1 !important;
      width: 30px;
      height: 30px;
    }
    .control-next {
      background: url("./arrow-right.png") no-repeat !important;
      // bottom: -5px !important;
      position: absolute !important;
      top: auto !important;
      opacity: 1 !important;
      width: 30px;
      height: 30px;
    }
    .control-arrow:before {
      border: none !important;
    }
    .carousel-status {
      display: none !important;
    }
  }
}

@media (max-width: 768px) {
  // .home-title {
  //   font-size: 40px !important;
  //   line-height: 34px !important;
  // }
}
@media (max-width: 991px) {
  .version-desktop {
    display: none !important;
  }
  .ctn-quiz {
    .quiz-content .quiz-item {
      margin: 50px 0 40px;
      .quiz-item-title {
        padding: 20px 0;
      }
    }
  }
}
