.voter-reg-page {
  .quiz-lp-top-header {
    display: flex;
    border-bottom: 10px solid #17bebb;
    text-align: center;
    margin: 0;
    align-items: center;
    justify-content: center;

    .top-header-title {
      width: 70%;
      margin: 0 1%;
      padding: 0 10px;
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-size: 25px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: -0.02em;
    }
  }

  .quiz-lp-top-content {
    padding: 0;
    .row {
      border-bottom: 5px solid #17bebb;
      padding: 15px 0;
      .col-right {
        border-left: 2px solid #17bebb;
        padding-left: 30px;
      }
    }
    .title {
      font-weight: 700;
      font-size: 40px;
      text-align: center;
      text-transform: uppercase;
      color: #193946;
    }

    .leader {
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      letter-spacing: -0.02em;
      text-align: center;
      color: #193946;
      padding: 20px 0px;
    }
  }

  .ctn-quiz-lp0102-rgtcontent {
    text-align: center;
    margin-top: 15px;

    .col-left {
      border-right: 2px solid #17bebb;
      text-align: center;
    }

    .col-right {
      text-align: center;
    }

    .title {
      margin: 6% 0;
      font-weight: 700;
      font-size: 80px;
      line-height: 68px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
    }

    .icon-title {
      display: block;
      margin: auto;
      padding-bottom: 20px;
    }

    p {
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      letter-spacing: -0.02em;
      text-align: center;
      color: #193946;

      a {
        text-decoration: underline;
        color: inherit;
      }

      a:hover {
        text-decoration: none;
      }
    }

    .btn-register {
      font-family: "garage-gothic", sans-serif;
      font-size: 88px;
      font-weight: 700;
      line-height: 68px;
      letter-spacing: -0.02em;
      background-color: #ec704c;
      color: #193946;
      text-transform: uppercase;
      padding: 10px 40px;
      border: none;

      &:hover {
        background-color: #17bebb;
      }
    }
    .section-bottom {
      border-top: 5px solid #17bebb;
      margin-top: 30px;
      .title {
        margin: 15px 0;
      }
      p {
        // margin-bottom: 0px;
      }
      .icon-title {
        display: inline-block;
      }
    }
  }
}

@media (max-width: 768px) {
  .voter-reg-page {
    .ctn-quiz-lp0102-rgtcontent {
      padding: 10px 5px;

      .col-left {
        border-bottom: 2px solid #17bebb;
        border-right: none;
        margin-bottom: 20px;
      }

      .title {
        font-size: 40px;
        line-height: 34px;
        margin: 15px 0;
        padding: 0;
        display: flex;
        justify-content: center;
      }

      p {
        font-size: 15px;
        line-height: 16.5px;
      }

      .btn-register {
        font-size: 35px;
        line-height: 30px;
        padding: 10px;
        margin-bottom: 20px;
      }
      .icon-title {
        height: 40px;
        display: inline;
        padding: 0;
        margin: -5px 5px 0;
      }
    }
    .quiz-lp-top-header {
      .icon-star-left {
        left: 5%;
        top: 30%;
      }

      .icon-star-right {
        right: 5%;
        top: 30%;
      }

      .top-header-title {
        font-size: 15px;
        line-height: 16.5px;
      }
    }

    .quiz-lp-top-content {
      padding: 10px 0px;

      .title {
        font-size: 20px;
        line-height: 17px;
      }

      .leader {
        font-size: 15px;
        line-height: 16.5px;
        padding: 10px 5px;
      }
      .row {
        .col-right {
          border: 0;
        }
      }
    }
  }
}
