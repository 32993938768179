.ctn-save-these-dates {
  margin: 40px 0px 0px 0px;
  background: #49397a;
  padding: 60px 30px;
  .save-date-image {
    width: 50%;
    flex-basis: 80%;
    margin: auto;
  }
  .div-below {
    background: #fafafc !important;
    height: 460px;
    .div-above {
      background: #fafafc !important;
      color: #1b0e44 !important;
      height: 460px;

      .title {
        margin-bottom: 10px;
      }
    }
  }

  .triangle::after {
    border-right-color: #ffffff !important;
  }
  .dog-ear-below {
    background: #49397a !important;
  }

  .save-date-item-title {
    font-weight: bold;
    //font-size: 1.5vw; // ManhLT commnent because It causes error in Mobile view
  }

  .save-date-item {
    margin-bottom: 20px;
    color: #fff;
    padding: 5px 0px 5px 50px;
    font-size: 24px;
    .save-date-item-info {
      margin: 10px 0 10px 10px;
      padding: 0px;
      //   font-weight: lighter;
      letter-spacing: 0.5px;
      list-style: "▸";
      list-style-position: outside !important;
      color: #f02d3a;
      li {
        margin: 10px 0;
        padding-left: 0.25em;
      }
      .light-bold {
        font-weight: 600;
      }
      div {
        display: inline;
        color: #fff;
      }
    }
  }
}
@media (max-width: 550px) {
  .ctn-save-these-dates {
    padding: 60px 10px 0px 10px;
    .div-background {
      .div-below {
        height: 330px !important;
        .div-above {
          height: 330px !important;
        }
      }
    }
    .save-date-item {
      padding: 0px 0px 20px 5%;
      font-size: 18px;
      &:first-child {
        margin-top: 50px;
      }
    }
  }
}

@media (max-width: 768px) {
}
