.ctn-sign-me-up {
  padding: 20px 0px;

  .title {
    font-family: "garage-gothic", sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 34px;
    letter-spacing: -0.02em;
    color: #193946;
    text-transform: uppercase;
    text-align: center;
  }
  .underline-emphasis {
    background-image: linear-gradient(to right, #17bebb 75%, #17bebb 75%);
    background-position: 0 0.8em;
    background-repeat: repeat-x;
    background-size: 8px 12px;
  }
  .cast-vote {
    padding: 10px 20%;
    text-align: center;

    .cast-vote-item {
      margin: 10px;
      border: 1px solid #eba56d;
      background: transparent;
      padding: 8px;
      color: #595959;
      text-align: left;
      &::placeholder {
        color: #595959 !important;
      }
      &:-ms-input-placeholder {
        color: #595959 !important;
      }
      &::-ms-input-placeholder {
        color: #595959 !important;
      }
    }
    .responsive-input {
      background: #f5f5f5;
      border: 2px solid #193946;
      font-family: "garage-gothic", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      text-transform: uppercase;
      color: #193946;
      outline: none;
      box-shadow: none;
      border-radius: 0;
      margin: 5px 0;
    }
  }

  .sign-me-up {
    width: 90%;
    margin: auto;
    text-align: center;

    .btn-register {
      font-family: "garage-gothic", sans-serif;
      font-size: 40px;
      font-weight: 700;
      line-height: 58px;
      letter-spacing: -0.02em;
      background-color: #ec704c;
      color: rgba(25, 57, 70, 1);
      text-transform: uppercase;
      padding: 5%;
      min-width: 174px;
      border: none;
    }
    .btn-register:hover {
      background-color: #17bebb;
    }
  }
}

@media (max-width: 768px) {
  .cast-vote {
    padding: 10px 5% !important;

    .cast-vote-item {
      width: 100% !important;
    }
  }

  .ctn-sign-me-up {
    .title {
      font-size: 20px;
      line-height: 20px;
    }
    .sign-me-up {
      .btn-register {
        font-size: 31px !important;
        line-height: 26px !important;
        min-width: 100px;
        padding: 2%;
        margin-top: 10px;
      }
    }
  }
}
