.App {
  color: white;
  padding: 40px;
  font-family: adelle, serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}

/* Apply custom cursor globally and for all states */
button, button span, button img, a, input, .hover, select, .clickable, option, slot {
  cursor: url('./cursor-hand.svg') 11 0, auto!important;
}

html, body, .disabled, a:disabled, input:disabled, label {
  cursor: url('./cursor-pointer.svg'), auto!important;
}

button:hover, a:hover, input:hover, .hover:hover {
  cursor: url('./cursor-hand.svg') 11 0, auto!important;
}

button:focus, a:focus, input:focus, .focus:focus {
  cursor: url('./cursor-hand.svg') 11 0, auto!important;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: url('./cursor-hand.svg') 11 0, auto!important;
}


#root{
  background-color: #FFFAF7;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}

body {
  background-color: #FFFAF7;
  font-family: "garage-gothic", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: rgba(25, 57, 70, 1);
}

#page-wrap {
  padding: 0px 38px 0px;
  max-width: 1440px;
  margin: auto;
}

.header-container {
  max-width: 1440px;
  margin: 15px auto;
}

button {
  font-family: "Avenir Next", sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc9d4 !important;
  opacity: 1 !important; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ccc9d4 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #ccc9d4 !important;
}

input.form-control {
  font-family: 'haboro-soft', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 18px;
  border: none;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 550px) {
	#page-wrap {
    padding: 0px 20px 0px;
  }
}

.disable-link {
  pointer-events: none;
}
