.more-info-container {
  margin-top: 40px;

  h3 {
    font-family: "haboro-soft";
    font-weight: 800;
    font-size: 32px;
    line-height: 35px;
    margin-bottom: 30px;
  }

  .btn-start {
    font-family: "garage-gothic", sans-serif;
    background-color: #ec704c;
    color: rgba(25, 57, 70, 1);
    text-transform: uppercase;
    outline: none;
    border: none;
    font-weight: 700;
    font-size: 30px;
    line-height: 31px;
    letter-spacing: -0.02em;
    padding: 5px 30px;
    margin-top: 30px;
  }

  .btn-start:hover {
    background: #17bebb;
  }

  img {
    max-width: 100%;
    @media (max-width: 576px) {
      margin-top: 20px;
    }
  }
}
