.calendar-section {
  width: 100%;
  padding-bottom: 20px;
  .title {
    font-family: "garage-gothic", sans-serif;
    font-weight: 700;
    font-size: 80px;
    line-height: 68px;
    margin: 40px auto;
    border-bottom: 5px solid #17bebb;
    padding-bottom: 30px;
    text-align: center;
  }
  .peach-color {
    border-bottom: 5px solid #ec704c;
  }
  .leader {
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 27.5px;
    flex-grow: 2;
    margin-bottom: 10px;
    a {
      text-decoration: underline;
      color: rgba(25, 57, 70, 1);
    }
    p {
      text-align: center;
    }
  }
  .ctn-text-content-note {
    background: rgba(235, 165, 109, 0.25);

    .text-content-note {
      // padding: 10px 10px 0px 10px !important;
      width: 100%;
      border: 2px solid #fffaf7;
      min-height: 66px;
      text-align: center;
      // display: inline-block;
    }
    .text-content-note-step {
      font-weight: 700;
      font-size: 40px;
      line-height: 34px;
      text-transform: uppercase;
      font-family: "garage-gothic", sans-serif;
      text-align: center;
      vertical-align: middle;
      letter-spacing: -0.02em;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      span.language-customization-es {
        font-size: 31px;
      }
    }
    .icon-hand-orange {
      margin-top: 3px !important;
      margin-left: 15px !important;
      float: left;
    }
    .text-content-note-t {
      float: right;
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-weight: 700;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: -0.02em;
      border-left: 4px solid #fffaf7;
      padding: 5px;
      a {
        font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        font-style: normal;
        color: #193946;
        text-decoration: underline;
      }
      a:hover {
        text-decoration: none;
        color: #193946;
      }
    }
    hr.calendar-hr {
      border-top: 4px solid white;
      margin: 0px 0px;
    }
  }
  .col-right {
    img {
      max-width: 100%;
    }
  }
}
.div-warning {
  margin: 10px 0 0;
  max-width: 100%;
}
@media (max-width: 768px) {
  .calendar-section {
    .title {
      font-size: 40px;
      line-height: 34px;
      margin: 20px 0;
      width: 100%;
      border-bottom: 0;
      padding-bottom: 0;
    }
    .ctn-text-content-note {
      .icon-hand-orange {
        margin-left: 5px !important;
        margin-top: 8px !important;
        height: 15px;
      }
      .text-content-note {
        min-height: 35px;
      }
      .text-content-note-t {
        font-size: 12.5px;
        span.language-customization-es {
          font-size: 11px;
        }
      }
      .text-content-note-step {
        font-size: 20px;
        line-height: 18px;
        padding: 6px;
        span.language-customization-es {
          font-size: 14px;
        }
      }
    }
  }
}
