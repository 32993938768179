button.download-button {
  margin: 30px 10px -20px 10px;
  font-weight: 800;
  color: white;

  a {
    text-align: left;
    font-weight: 800;
    color: white;
  }
}
.no-races {
  margin: 40px 0;
}

p {
  font-size: 25px;
  line-height: 28px;
}

.citation-container {
  z-index: 10;
  position: relative;
  .citation-tooltip {
    font-size: 20px;

    line-height: 1.714;
    border-radius: 4px;
    background-color: #000;
    pointer-events: auto !important;
  }
}

@media (max-width: 768px) {
  p {
    font-size: 15px;
    line-height: 18px;
  }

  .no-races {
    margin-top: 20px;
  }

  .citation-container {
    .citation-tooltip {
      font-size: 18px;
      line-height: .75;
    }
  }
}
