.ctn-send-email {
  background: #1b0e44;
  padding: 80px 10px;
  .col-left img {
    width: 400px;
    margin-bottom: 10%;
  }
  .div-below {
    background: #fafafc !important;
    height: 460px;
    .div-above {
      background: #eef0f6 !important;
      color: #1b0e44 !important;
      height: 460px !important;
      .title {
        margin-bottom: 10px;
      }
    }
  }
  .triangle::after {
    border-right-color: #eef0f6 !important;
  }
  .dog-ear-below {
    background: #1b0e44 !important;
  }
  .action_form {
    flex: auto;
    flex-flow: column wrap;
  }
  .input-email {
    padding: 3px 10px;
    margin: 15px 0px;
    border: 1px solid #cecece;
    &:focus {
      border: 1px solid #cecece;
    }
  }
  .btn-send-email {
    color: #fff;
    background: #f00;
    font-size: 24px;
    font-family: "Avenir Next", sans-serif;
    font-weight: bold;
    margin-left: 5px;
    margin-top: 20px;
    border: none;
    padding: 0 15px;
    text-transform: uppercase;
  }
  .ak-grid-row {
    width: 90%;
    // padding-right: 10px;
    align-self: flex-start;
  }
  #signup-form {
    width: 100%;
    input {
      border: 1px solid #1b0e44;
      border-radius: 0px;
      width: 100%;
    }
    .form-group {
      margin: 5px;
      width: 100%;
    }
  }
  #id_email.ak-userfield-input {
    width: 100%;
  }
}
@media (max-width: 550px) {
  .ctn-send-email {
    .div-background {
      padding: 0px 5%;
      .div-below {
        height: 300px !important;
        .div-above {
          height: 300px !important;
        }
      }
    }
    .col-left {
      display: none;
    }
    .btn-send-email {
      font-size: 18px;
    }
  }
}

@media (max-width: 998px) {
  .ctn-send-email {
    .col-left img {
      display: block;
      margin: auto;
      margin-bottom: 35px;
    }
  }
}
