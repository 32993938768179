.bg-emphasis {
  background-image: linear-gradient(
    to right,
    rgba(171, 225, 136, 0.4) 75%,
    rgba(171, 225, 136, 0.4) 75%
  );
  background-position: 0;
  background-repeat: repeat-x;
  background-size: 100% 75%;
}

.bg-emphasis-red {
  background-image: linear-gradient(
    to right,
    rgba(245, 181, 162, 1) 75%,
    rgba(245, 181, 162, 1) 75%
  );
  background-position: 0;
  background-repeat: repeat-x;
  background-size: 100% 75%;
}

p.bg-emphasis-3rd {
  display: inline;
  background: rgba(171, 225, 136, 0.4) 75%;
  line-height: 31px!important;
}

.quiz-landing-header {
  padding: 20px 0px;

  .quiz-landing-title {
    text-align: center;
    font-weight: 700;
    color: #193946;
    font-size: 80px;
    letter-spacing: -0.02em;
    line-height: 68px;
    text-transform: uppercase;
    max-width: 788px;
    margin: auto;

    .underline-emphasis {
      background-image: linear-gradient(to right, #ec704c 75%, #ec704c 75%);
      background-position: 0 0.8em;
      background-repeat: repeat-x;
      background-size: 8px 12px;
    }
  }
  .quiz-landing-title-width-90 {
    max-width: 90%;
  }

}

span.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.election-day-title {
  font-family: "garage-gothic", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
  text-transform: uppercase;
}

.div-warning {
  .underline-emphasis {
    background-image: linear-gradient(to right, #ec704c 75%, #ec704c 75%);
    background-position: 0 0.9em;
    background-repeat: repeat-x;
    background-size: 5px 5px;
  }

  position: relative;

  .warning-title {
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 700;
    margin-left: 50px;
  }

  .icon-warning {
    position: absolute;
    top: 15px;
  }

  p {
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #193946;
    text-align: justify;
  }
}

.quiz-lp-top-header {
  display: flex;
  padding: 20px 10%;
  border-bottom: 10px solid #17bebb;
  text-align: center;
  margin: 0;
  align-items: center;
  justify-content: center;

  .top-header-title {
    width: 70%;
    margin: 0 1%;
    padding: 0 10px;
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.02em;

    p {
      margin-bottom: 0px;
    }
  }
}

.quiz-lp-top-content {
  padding: 10px 95px;
  .row {
    border-bottom: 5px solid #17bebb;
    padding: 30px 0px;
    .col-right {
      border-left: 2px solid #17bebb;
      padding-left: 30px;
    }
  }
  .title {
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
    color: #193946;
  }

  .leader {
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: center;
    color: #193946;
    padding: 20px 0px;
  }
}

.ctn-quiz-lp03 {
  .quiz-lp-top-content {
    padding: 20px 0px;
  }

  .collapse-text-content {
    .col-left {
      width: 100%;
      float: left;
    }
    .ctn-text-content-note {
      background: rgba(235, 165, 109, 0.25);
      padding: 10px 30px;
      margin: 2% 0px 5% 0px;
      .text-content-note {
        padding: 0px 0px 10px 10px !important;
        border-bottom: 4px solid #fff;
      }
      .text-content-note-step {
        max-width: 80px;
        width: 15%;
        float: left;
        font-weight: 700;
        font-size: 40px;
        line-height: 34px;
        text-transform: uppercase;
        font-family: "garage-gothic", sans-serif;
        &.language-customization-kr {
          font-size: 30px;
        }
      }
      .icon-hand-orange {
        margin-top: 3px !important;
        margin-left: 15px !important;
      }
      .text-content-note-t {
        // width: auto !important;
      }
    }
    .col-right {
      width: 0%;
      float: left;
    }
  }
}

.quiz-lp-btn-action {
  text-align: center;
  // border-bottom: 5px solid #17BEBB;
  padding: 50px 0px;

  .btn-action-item {
    font-family: "garage-gothic", sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: -0.02em;
    color: #193946;
    text-transform: uppercase;
    padding: 20px 40px;
    border: 3px solid #ec704c;
    background: transparent;
    margin: 5px;
    outline: none;

    &:hover {
      background-color: #ec704c;
    }

    a {
      color: #193946;
      text-decoration: none;
    }
  }
}

.collape-item {
  .collape-item-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    border: 4px solid #17bebb;
    border-width: 4px 0px 0px 0px;
    padding: 20px 50px;

    .collape-item-lable {
      font-size: 80px;
      font-weight: 700;
      line-height: 68px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      float: left;
    }

    .collape-item-button {
      float: right;
      border: none;
      outline: none;
      background: transparent;
      margin-top: 0px;

      .icon-minus {
        margin-top: 30px;
      }
    }
  }
  #collapse-text-02 {
    .text-content-note {
      margin: 0px;
    }
    .text-content-note-t {
      width: 75%;
    }
  }

  .collapse-text-content {
    padding: 20px 50px;

    .underline-emphasis {
      background-image: linear-gradient(to right, #ec704c 75%, #ec704c 75%);
      background-position: 0 0.85em;
      background-repeat: repeat-x;
      background-size: 8px 5px;
    }
    .collapse-text-item {
      display: inline-block;
      width: 100%;
      .content {
        .text-content-note {
          &.sublist-1 {
            margin-left: 2em;
          }
          .icon-hand-orange {
            max-width: 8%;
            margin-right: 2%;
            float: left;
            margin-top: 4px;
            object-fit: contain;
          }
        }
      }
    }

    .col-left {
      float: left;

      .text-title {
        font-weight: 700;
        font-size: 40px;
        text-align: left;
        color: #193946;
        text-transform: uppercase;
      }

      .text-content {
        font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        font-weight: 500;
        font-size: 25px;
        line-height: 30px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #193946;
        margin-bottom: 20px;

        a {
          text-decoration: underline;
          color: inherit;
        }

        a:hover {
          text-decoration: none;
        }
      }

      .text-content-note {
        display: inline-block;
        width: 100%;
        font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        font-weight: 500;
        font-size: 25px;
        line-height: 30px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #193946;
        margin-bottom: 3%;

        .icon-hand-orange {
          max-width: 8%;
          margin-right: 2%;
          float: left;
          margin-top: 4px;
          object-fit: contain;
        }

        .text-content-note-t {
          float: left;
          width: 75%;
          a {
            text-decoration: underline;
            color: inherit;
          }
          a:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .btn-action-quiz-lp {
    font-family: "garage-gothic", sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 34px;
    padding: 10px;
    letter-spacing: -0.02em;
    background-color: #ec704c;
    color: #193946;
    text-transform: uppercase;
    border: none;
    margin-top: 30px;
  }

  #collapse-text-03 {
    .text-content-3 {
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-size: 25px;
      text-align: left;
      color: #193946;
      margin-bottom: 20px;
    }
    .div-warning {
      padding: 10px 0;
      margin-top: 20px;
      .btn-action-quiz-lp {
        margin-top: 0;
      }
      p {
        padding: 0 15px;
      }
    }
    .turning-ballot {
      text-align: center;

      .turning-ballot-item {
        border-left: 5px solid #17bebb;

        .turning-ballot-item-img {
          max-width: 80%;
        }

        .turning-ballot-item-title {
          font-size: 40px;
          font-weight: 700;
          line-height: 40px;
          padding: 20px 0;
          text-transform: uppercase;
        }

        .turning-ballot-answer {
          clear: both;
          font-size: 25px;
          color: #193946;
          padding: 0px 10px;
          font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
            sans-serif;
        }
        button {
          &:hover {
            background-color: #17bebb;
          }
        }
        a {
          text-decoration: underline;
          color: inherit;
        }
        a:hover {
          text-decoration: none;
        }
      }

      .turning-ballot-item:first-child {
        border-left: none;
      }
    }
  }

  button {
    &:hover {
      background-color: #17bebb;
    }
  }
}

.ctn-quiz-lp04 {
  .collapse-text-content.contact-us {
    .col-left {
      width: 100%;
    }
    .col-right {
      width: 0%;
    }
  }

  .collapse-text-content {
    a {
      text-decoration: underline;
      color: inherit;
    }
    a:hover {
      text-decoration: none;
    }
    .col-left {
      width: 55%;
      float: left;

      .btn-action-what {
        font-family: "garage-gothic", sans-serif;
        font-size: 40px;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: -0.02em;
        background: transparent;
        padding: 15px 10px;
        text-transform: uppercase;
        margin-bottom: 20px;
        width: 90%;
        text-align: left;
        border: 2px solid #193946;
        color: #193946;
        text-decoration: none !important;
        &:hover,
        &:focus {
          border: 2px solid #ec704c;
          outline: none;
        }
      }
    }

    .col-right {
      width: 45%;
      float: left;
      text-align: center;

      img {
        width: 95%;
      }

      .title {
        font-family: "garage-gothic", sans-serif;
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-left: 20px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }

      .content {
        font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        font-weight: 500;
        font-size: 25px;
        letter-spacing: -0.02em;
        line-height: 30px;
        text-align: left;
        margin: 0 25px;
        .text-content-note {
          display: inline-block;
          width: 100%;
          font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
            sans-serif;
          font-weight: 500;
          font-size: 25px;
          line-height: 30px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #193946;
          margin-bottom: 3%;

          .icon-hand-orange {
            max-width: 8%;
            margin-right: 2%;
            float: left;
            margin-top: 4px;
            object-fit: contain;
          }

          .text-content-note-t {
            float: left;
            width: 75%;
            a {
              text-decoration: underline;
              color: inherit;
            }
            a:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .collape-item {
    border-top: 5px solid #17bebb;
  }

  .collape-item:first-child {
    border-top: none;
  }

  .btn-action-item {
    padding: 10px 20px;
  }

  .collape-item-top {
    border: none;
  }

  .collape-item-leader {
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 25px;
    text-align: left;
    color: #193946;
    margin-bottom: 20px;
    clear: both;
    padding: 0px 0px 30px 0px;
    border-bottom: 4px solid #17bebb;
    line-height: 30px;
  }
  .active {
    border: 2px solid #ec704c !important;
  }

  .mobile-collapse {
    .collape-item-top {
      border: 4px solid #17bebb;
      border-width: 2px 0px 2px 0px;

      .collape-item-lable {
        font-weight: 700;
        font-size: 25px;
      }

      .collape-item-button {
        img {
          width: 20px !important;
        }
      }
    }
    .content {
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-weight: 500;
      clear: both;
      letter-spacing: -0.02em;
      .text-content-note {
        display: inline-block;
        width: 100%;
        font-size: 25px;
        text-align: left;
        color: #193946;
        margin-bottom: 3%;
        line-height: 15px !important;
        .text-content-note-t {
          font-size: 15px;
          float: left;
          width: 90% !important;
        }

        &.sublist-1 {
          margin-left: 2em;
          width: 80%;
        }
        .icon-hand-orange {
          max-width: 8%;
          margin-right: 2%;
          float: left;
          margin-top: 0px;
          height: 20px;
          object-fit: contain;
        }
      }
    }
    a {
      text-decoration: underline;
      color: inherit;
    }

    a:hover {
      text-decoration: none;
    }
  }
}

.ctn-quiz-lp05 {
  .collapse-text-content {
    .col-left {
      float: left;
      width: 55%;
    }
    .col-right {
      float: left;
      width: 45%;
    }
  }
  #collapse-text-05 {
    .col-left {
      width: 100%;
      a {
        color: #193946;
        text-decoration: underline;
      }
    }
    .col-right {
      width: 0%;
    }
  }
  #collapse-text-06 {
    .col-left {
      width: 100%;
    }

    .max-850px-container {
      max-width: 850px;
      margin: auto;
    }
  }
  .btn-action-quiz-lp {
    margin-bottom: 50px;
  }
}

.ctn-email-signup {
  .form-control {
    border: 1px solid #ec704c !important;
  }
}

.office-description {
  .quiz-lp-btn-action {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    padding: 5px;
    .btn-action-item {
      padding: 10px;
    }
  }
  .collape-item {
    .collapse-text-content {
      .collapse-text-item {
        display: flex;
      }
      h6 {
        font-size: 1.4rem;
      }
    }
  }
  .btn-back-to-top {
    color: #193946;
    font-weight: 700;
    font-size: 30px;
    line-height: 25.5px;
    font-family: "garage-gothic", sans-serif;
    background: #17bebb;
    text-transform: uppercase;
    border: none;
    padding: 10px;
  }
  .ballot-container {
    margin: 0px;
    border: none;
    text-align: left;
  }
  .col-right {
    border-left: 1px solid #17bebb;
  }
}

@media (max-width: 768px) {
  .quiz-landing-header {
    padding: 5px 0;
    .quiz-landing-title {
      font-size: 40px;
      line-height: 34px;
      padding: 0px 5%;
    }
    p {
      padding: 5px !important;
      margin-bottom: 0;
    }
    .quiz-landing-title-mobile-full {
      padding: 0px;
      max-width: 100%;
    }
  }

  .quiz-lp-top-header {
    padding: 0 0 5px;

    .icon-star-left {
      left: 5%;
      top: 30%;
    }

    .icon-star-right {
      right: 5%;
      top: 30%;
    }

    .top-header-title {
      font-size: 12px;
      line-height: 15px;
    }
  }

  .quiz-lp-top-content {
    padding: 10px 0px;

    .title {
      font-size: 20px;
      line-height: 17px;
    }

    .leader {
      font-size: 15px;
      line-height: 16.5px;
      padding: 10px 5px;
    }
    .row {
      .col-right {
        border: 0;
      }
    }
  }

  .div-warning {
    margin-top: 10px;
    .warning-title {
      font-size: 22px;
      line-height: 26px;
      margin-left: 25px;
    }
    .icon-warning {
      position: absolute;
      top: 4px;
      width: 20px;
    }
    p {
      font-size: 18px;
      line-height: 20px;
      text-align: left;
      padding: 10px 0px;
    }
  }

  .ctn-email-signup {
    display: block !important;

    .form-control {
      border: 1px solid #17bebb !important;
    }
  }

  .quiz-lp-btn-action {
    display: none;
  }
  .office-description {
    .quiz-lp-btn-action {
      padding: 5px;
      .btn-action-item {
        font-size: 15px;
        line-height: 12.75px;
        padding: 10px;
      }
    }
    .collape-item-lable {
      font-size: 35px;
    }
    .collape-item {
      .collape-item-top {
        padding: 10px 0px;
      }
    }
  }
  .collape-item {
    .collapse-text-content {
      .col-left {
        width: 100% !important;

        .text-content-note {
          line-height: 15px;
          .text-content-note-t {
            font-size: 15px;
            float: left;
            width: 90%;
          }
        }
      }
    }

    .collape-item-top {
      padding: 10px;

      .collape-item-lable {
        font-size: 40px;
        line-height: 34px;
        // width: 80%;
        float: left;
      }

      .collape-item-button {
        .icon-minus {
          margin-top: 0px;
        }

        img {
          width: 30px;
        }
      }
    }

    .collapse-text-content {
      padding: 10px 0px;

      .text-title {
        font-size: 25px !important;
      }

      .text-content {
        font-size: 15px !important;
        line-height: 16.5px !important;
        margin-top: 2%;
      }
    }

    #collapse-text-03 {
      .text-title {
        font-size: 25px;
      }

      .text-content-3 {
        font-size: 15px;
        line-height: 16.5px;
      }
      .turning-ballot {
        .turning-ballot-item {
          width: 100%;
          border-top: 5px solid #17bebb;
          border-left: 0px;
          padding-bottom: 20px;

          .turning-ballot-item-title {
            font-size: 25px;
            padding: 10px 0px;
            text-align: left;
            text-transform: uppercase;
          }

          .turning-ballot-answer {
            padding: 0px;
            font-size: 15px;
            line-height: 16.5px;
            text-align: left;
          }
          .turning-ballot-action {
            text-align: left;
          }
        }
      }
      .div-warning {
        p {
          padding: 0;
        }
      }
    }

    .btn-action-quiz-lp {
      font-size: 25px;
    }
  }

  .ctn-quiz-lp03 {
    #collapse-text-02 {
      .text-content-note-t {
        width: 65%;
      }
    }
    .collapse-text-content {
      .ctn-text-content-note {
        background: rgba(235, 165, 109, 0.25);
        padding: 10px 30px;
        margin: 2% 0px 5% 0px;
        .text-content-note {
          padding: 0px 0px 10px 10px;
          border-bottom: 4px solid #fff;
        }
        .text-content-note-t {
          width: 50%;
          font-size: 15px;
          line-height: 16.5px;
          height: 100%;
        }
        .text-content-note-step {
          max-width: 45px;
          width: 20%;
          height: 30px;

          font-size: 25px;
          &.language-customization-kr {
            font-size: 17px;
          }
        }
        .div-warning {
          .warning-title {
            margin-top: 0px;
          }
        }
      }
    }
  }

  .ctn-quiz-lp04 {
    .collapse-text-item {
      display: flex !important;
      flex-direction: column;
      align-items: flex-start;
    }

    .col-left {
      order: 2;
    }

    .col-right {
      order: 1;
      width: 100% !important;
      flex-direction: column;
      align-items: flex-start;

      img {
        width: 100% !important;
      }

      .section-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .col-right-text {
        order: 2;
        border-bottom: 2px solid #17bebb;
        margin-bottom: 20px;
        padding-bottom: 15px;
      }

      .col-right-image {
        order: 1;
      }

      .title {
        font-size: 25px !important;
        line-height: 30px !important;
        margin: 10px 0px 0px 0px !important;
      }

      .content {
        font-size: 15px !important;
        line-height: 16.5px !important;
        margin: 10px 0px !important;
      }
    }

    .collape-item-leader {
      font-size: 15px;
      line-height: 16.5px;
      padding: 10px 0px;
    }

    .btn-action-what {
      display: none;
    }
  }
  .collape-item
    .collapse-text-content
    .col-left
    .text-content-note
    .icon-hand-orange {
    max-width: 8%;
    margin-right: 2%;
    margin-top: 0px;
    height: 20px;
  }

  p.bg-emphasis-3rd {
    line-height: 16px!important;
  }
}

.video-embed-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  .accessibility-guide-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

@media (max-width: 1200px) {
  .collape-item {
    #collapse-text-03 {
      .turning-ballot {
        .turning-ballot-item {
          width: 100%;
          border-top: 5px solid #17bebb;
          border-left: 0px;
          padding-bottom: 20px;
        }
      }
    }
  }
}
