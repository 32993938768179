form#create_personalized_lower {
  margin: 50px 0px;
  padding: 32px 0px 22px;
  .row {
    display: flex;
    justify-content: center;
    margin: 0;
  }
  .collection-title {
    font-size: 48px;
    line-height: 41px;
    letter-spacing: -0.02em;
    color: #193946;
    font-weight: 700;
    font-family: "garage-gothic", sans-serif;
    text-align: center;
    // margin-right: 10px;
    margin-bottom: 22px;
    @media (max-width: 768px) {
      font-size: 32px !important;
      line-height: 32px !important;
    }
    &.language-customization-kr {
      font-size: 21px;
    }

  }
  button.button-submit:disabled {
    background: #ec714b;
    opacity: 1;
  }

  button.button-submit:active,
  button.button-submit:focus,
  button.button-submit:hover {
    outline: none;
    box-shadow: none;
    background: #ec714b;
  }

  .alert-warning {
    border: none;
    border-radius: 0;
    background: #17bebb;
    color: #193946;
    font-family: "garage-gothic", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-warning {
      width: 25px;
      margin: 0 5px;
    }
  }
  .bg-green {
    background: #17bebb;
  }
}

@media (max-width: 991px) {
  form#create_personalized {
    button.button-submit img {
      width: 30px;
      margin: 0px 5px 5px 0px;
    }
  }
}
