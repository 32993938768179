.footer-container {
  font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #193946;
  margin-top: 25px;
  p {
    font-size: 15px;
    line-height: 22px;
  }
  .fa-sm {
    font-size: 0.95em;
  }
  .footer-top {
    border: 1px solid #ec704c;
    padding: 15px 30px;
    .container {
      padding: 10px;
    }
    .helpful-links {
      h4 {
        font-family: "garage-gothic", sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
      }
      a {
        text-decoration: none;
        color: #193946;
      }
      a:hover {
        color: #17bebb;
      }
      p {
        margin-bottom: 0px;
      }
    }
  }
  .footer-bottom {
    text-align: center;
    // font-size: 20px;
    color: #193946;
    margin: 30px 0px;
    a {
      color: #193946;
      text-decoration: underline;
    }
    a:hover {
      color: #193946;
      text-decoration: none;
    }
  }
  .footer-bottom-spacer {
    margin: 0px 1%;
  }
  .social-spacing {
    text-align: right;
    padding-bottom: 70px;
    display: flex;
    flex-flow: row nowrap;
  }

  .social-text {
    font-size: calc(1em + 1vw);
    font-family: "avenir_bold";
    color: rgb(255, 255, 255);
    font-weight: bold;
    line-height: 1.2;
    text-align: right;
  }
}
@media (max-width: 767px) {
  .footer-container {
    font-size: 12px;
    line-height: 16px;
    p {
      font-size: 12px;
      line-height: 16px;
    }
    .social-text {
      text-align: center;
    }
    .footer-top {
      padding: 10px 15px;
      .helpful-links h4 {
        font-size: 15px;
        line-height: 18px;
      }
    }
    .about-footer {
      margin-top: 10px;
    }
    .social-text {
      text-align: left;
      padding-top: 10px;
    }
  }
}
