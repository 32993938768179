.ctn-goal-meter {
  margin: 0 auto;
  background: #193946;
  position: fixed;
  bottom: 0px;
  z-index: 1;
  padding: 0px;
  left: 0;
  .move-icon{
    top:10px;
  }
  .goal-meter-details {
    .goal-meter-title {
      text-transform: uppercase;
      color: #fff;
      font-size: 40px;
      line-height: 43px;
      text-align: left;
      font-weight: 600;
    }
    h4 {
      color: white;
      margin-right: 30px;
      font-size: 34px;
      line-height: 35px;
      margin-bottom: 0px;
    }
    .goal-meter-button {
      border: 2px solid #ec704c;
      text-transform: uppercase;
      color: #fff;
      font-size: 34px;
      line-height: 35px;
      padding: 5px 20px;
      float: left;
      margin-top: 10px;
      font-weight: 600;
      cursor: inherit;
      a {
        text-decoration: none;
      }
    }
    .text-guide{
      border: 2px solid #ec704c;
      text-transform: uppercase;
      font-size: 34px;
      line-height: 35px;
      padding: 5px 20px;
      margin-top: 10px;
      font-weight: 600;
      color: #000;
      background: transparent;
      color: #fff;
      font-family: "garage-gothic", sans-serif;
      margin-left: 10px;
      &:hover{
        background-color: #ec704c;
        text-decoration: none;
      }
    }
    .goal-meter-button:hover {
      background-color: #ec704c;
      text-decoration: none;
    }
    .download-guide,.text-complete {
      background: #abe188;
      color: #000;
      border: none;
    }
  }
  .ctn-goal-meter-content {
    padding: 0 38px;
    position: relative;
    justify-content: space-between;
  }
  .goal-meter-progress-bar {
    display: inline-grid;
    align-items: center;
    .goal-meter-progress-step {
      position: relative;
    }
    .item-step {
      background: #193946;
      width: 25%;
    }
    .active {
      background: #abe188;
    }
    .first-step {
      border: 4px solid #ec704c;
      padding: 18px;
      border-radius: 40px 0px 0px 40px;
      float: left;
      width: 25%;
    }
    .next-step {
      float: left;
      border: 4px solid #ec704c;
      padding: 18px;
      transform: skew(20deg);
      margin-left: -15px;
    }
    .last-step {
      float: left;
      border: 4px solid #ec704c;
      padding: 18px;
      border-radius: 0px 40px 40px 0px;
      margin-left: -15px;
    }
    .one-race{
      .last-step{
        border-left: 0px !important;
      }
    }
    .check-done {
      position: absolute;
      background: #fff;
      padding: 25px;
      z-index: 2;
      border-radius: 50%;
      left: 40%;
      top: -30px;
    }
  }
}

.ctn-goal-meter.collapse {
  display: flex;
  height: 60px;
  align-items: center;
}

.ctn-goal-meter.collapse.show {
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collapsed-goal-meter {
  img.icon-drop-down-white {
    transform: scale(-1);
  }
  h4 {
    color: white;
    margin-bottom: 0px;
  }
  
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .ctn-goal-meter.collapse.show {
    height: fit-content;
    padding: 15px 0;
  }
  .ctn-goal-meter.collapse {
    height: 32px;
    align-items: end;
  }
  .ctn-goal-meter {
    .ctn-goal-meter-content {
      padding: 0px 20px;
      .icon-drop-down-white {
        position: absolute;
        right: 15px;
        top: 0px;
        width: 20px;
      }
      .goal-meter-buttons-container {
        position: absolute;
        right: 0;
      }
      .goal-meter-details {
        .goal-meter-button {
          font-size: 18px;
          line-height: 20px;
          padding: 5px 5px;
          margin-top: 0px;
        }
        h4 {
          color: white;
          margin-right: 30px;
          font-size: 20px;
          line-height: 18px;
          margin-bottom: 0.5rem;
        }
        .text-guide{
          font-size: 18px;
          line-height: 20px;
          padding: 5px 5px;
        }
      }
      .goal-meter-progress-bar {
        margin: 5px 0px 0px -10px;
        .first-step,
        .next-step,
        .last-step {
          border: 2px solid #ec704c;
          padding: 8px;
        }
        .next-step,
        .last-step {
          margin-left: -8px;
        }
        .second-last {
          padding: 8px 6px;
        }
        .check-done {
          width: 50px;
          height: 50px;
          top: -10px;
          padding: 12px;
          left: 45%;
        }
        img {
          width: 30px;
        }
      }
    }
  }
  .collapsed-goal-meter {
    .ctn-goal-meter-content {
      .icon-drop-down-white {
        position: absolute;
        right: 15px;
        top: 3px;
        width: 20px;
      }
    }
  }
}
