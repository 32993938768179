.ctn-line-split {
  width: 100%;
  height: 30px;
  background: #ec704c;
  padding: 0px 15px;
  margin: 15px 0px;
}
.bg-green {
  background: #17bebb;
}

.ctn-line-split.short-line-split {
  height: 2px;
}
