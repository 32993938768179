.vote-with-a-record {
  p {
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #193946;
  }

  a.resource-button {
    font-family: "garage-gothic";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #193946;
    display: block;
    width: 100%;
    border: 2px solid #ec704c;
    text-decoration: none;
    margin: 5px 0;
    padding: 16px 5px;
  }

  a.resource-button:hover {
    background: #ec704c;
  }

  .resource-image {
    width: 100%;
    margin: 5px 0;
  }

  .collapse-text-content .col-right .title {
    text-transform: initial;
  }

  .text-content-note {
    display: inline-block;
    width: 100%;
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #193946;
    margin-bottom: 3%;

    .icon-hand-orange {
      max-width: 8%;
      margin-right: 2%;
      float: left;
      margin-top: 4px;
      object-fit: contain;
    }

    .text-content-note-t {
      float: left;
      width: 90%;
      a {
        text-decoration: underline;
        color: inherit;
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .vote-with-a-record {
    p {
      font-size: 15px;
      line-height: 18px;
    }
    #resources {
      border-top: 0;
      .collape-item-top {
        border-top: 5px solid #17bebb;
      }
    }
  }
}
