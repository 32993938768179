.race-page-address {
  h3 {
    font-weight: 800;
    font-size: 64px;
    line-height: 54px;
    margin-top: 35px;
  }
  form#create_personalized {
    margin: 35px 0;
  }
}

@media (max-width: 768px) {
  .race-page-address {
    h3 {
      font-size: 35px;
      line-height: 30px;
    }
  }
}
