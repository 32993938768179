.device-layout-component {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  span.download-pdf {
    background: #ec704c;
    color: white;
    max-width: 200px;
    line-height: 30px;
    padding: 2px 5px 2px 12px;
    font-size: 30px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    font-family: "garage-gothic", sans-serif;
  }

  span.download-pdf:hover,
  span.download-pdf:focus,
  span.download-pdf:active {
    text-decoration: none;
    background: #17bebb;
  }
}
