.quiz-container {
  margin-bottom: 50px;
  h4 {
    font-size: 32px;
    font-family: "avenir_regular";
    color: rgba(25, 57, 70, 1);
  }
  .quiz-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #eef0f6 !important;
    outline: none;
    border: none;
    transition: 0.3s;
    span {
      color: #88829f;
    }
  }

  .quiz-button:hover {
    background-color: rgba(25, 57, 70, 1) !important;
    span {
      color: #ffffff;
    }
  }

  .quiz-button:focus,
  .quiz-button:active {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(27, 14, 68, 0.5);
  }
}
