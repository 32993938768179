.ctn-answer-questions {
  margin: 3% 0;
  .col-left {
    border-right: 2px solid #ec704c;
    text-align: center;
    img {
      width: 370px;
    }
  }
  .col-right {
    margin: 0 auto;
    text-align: center;
    .quiz {
      width: 80%;
    }
    p {
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-size: 25px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.02em;
      margin: 0px 10% 20px;
    }
    .title {
      font-family: "garage-gothic", sans-serif;
      font-weight: 700;
      font-size: 80px;
      line-height: 68px;
      letter-spacing: -0.02em;
      margin: 30px 7px;
    }
    .last-step{
      font-family: "garage-gothic", sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 85%; /* 34px */
      letter-spacing: -0.8px;
    }
    .btn-start {
      font-size: 100px;
      line-height: 124px;
      font-weight: 700;
      font-family: "garage-gothic", sans-serif;
      background-color: #ec704c;
      color: rgba(25, 57, 70, 1);
      text-transform: uppercase;
      padding: 0 95px;
      outline: none;
      border: none;
      letter-spacing: -0.02em;
      &.btn-plan {
        height: 100%;
      }
    }

    .btn-yes-no {
      font-size: 90px;
      line-height: 100px;
      padding: 0 20px;
      &.language-customization-kr {
        font-size: 60px;
        line-height: 90px;
      }
    }
    .btn-start:hover {
      background: #17bebb;
    }

    .btn-second {
      letter-spacing: -0.02em;
      border: 4px solid #ec704c;
      text-transform: uppercase;
      background-color: #ec704c;
      font-family: "garage-gothic", sans-serif;
      font-size: 60px;
      font-weight: 700;
      line-height: 68px;
      color: rgba(25, 57, 70, 1);
      outline: none;
      box-shadow: none;
      min-width: 220px;
      margin-top: 25px;
    }

    .btn-second:hover {
      border: 4px solid #17bebb;
      background: #17bebb;
    }

    .btn-second-inactive {
      border: 4px solid #ec704c;
      background: #fffaf7;
    }

    .btn-second-inactive:hover {
      border: 4px solid #ec704c;
      background: #fffaf7;
      cursor: inherit;
    }

    .btn-quiz {
      width: 100%;
      border: none;
    }
    .btn-quiz:hover {
      width: 100%;
      border: none;
    }

    .btn-small {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 31px;
      letter-spacing: -0.02em;
      width: 100%;
      padding: 5% 2%;
    }

    .btn-medium {
      font-size: 80px;
      font-weight: 700;
      line-height: 68px;
      padding: 5%;
    }

    .form-input {
      background: #f5f5f5;
      border: 2px solid #193946;
      font-family: "garage-gothic", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      text-transform: uppercase;
      color: #193946;
      outline: none;
      box-shadow: none;
      border-radius: 0;
      margin: 5px 0;
    }

    .regcheck-start-over {
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-size: 25px;
      line-height: 27.5px;
      font-weight: 500;
      letter-spacing: -0.02em;
      color: #193946;
      text-align: center;
      border-top: 5px solid #ec704c;
      width: 92%;
      margin: 25px auto 0px;
      padding: 30px 0px 15px;
      cursor: inherit;
      .btn-startover {
        margin-left: 1%;
      }
      svg {
        margin-left: 20px;
      }
    }
  }
  .ctn-keep-me {
    margin: 10px 0px 0px 4px;
    position: relative;
    cursor: inherit;
    .ctn-keep-me-icon {
      float: left;
      width: 30px;
      margin-top: 5px;
    }
    .ctn-keep-me-icon-checked {
      position: absolute;
      left: 18px;
      top: 0px;
    }
    .ctn-keep-me-text {
      text-align: left;
      font-size: 25px;
      font-weight: 700;
      text-transform: uppercase;
      margin-left: 40px;
      font-family: "garage-gothic", sans-serif;
    }
  }
}

@media (max-width: 1200px) {
  .ctn-answer-questions {
    .form-input {
      font-size: 20px !important;
    }
    .ctn-keep-me-text {
      font-size: 20px !important;
      margin-top: 5px;
    }
    .col-right {
      .title {
        font-size: 60px;
        line-height: 54px;
        // margin: 0 0 25px 0;
      }
    }
  }
}

@media (max-width: 992px) {
  .ctn-answer-questions {
    .form-input {
      font-size: 20px !important;
    }
    .ctn-keep-me-text {
      font-size: 20px !important;
      margin-top: 5px;
    }
    .col-left {
      border-right: none !important;
      text-align: center;
      img {
        width: 350px;
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .ctn-answer-questions {
    .form-input {
      font-size: 20px !important;
    }
    .ctn-keep-me-text {
      font-size: 20px !important;
      margin-top: 5px;
    }
    .col-left {
      border-right: none !important;
      text-align: center;
      img {
        width: 208px;
      }
    }
    .col-right {
      .title {
        font-size: 35px;
        line-height: 30px;
        margin: 0px 0px 20px 0px;
      }
      p {
        font-size: 15px;
        line-height: 16.5px;
        margin: 0;
        margin-bottom: 1rem;
      }
      .last-step{
        font-size: 18px;
      }
      .btn-start {
        padding: 3% 10%;
        font-size: 60px;
        font-weight: 700;
        line-height: 51px;
        margin-bottom: 10px;
        &.btn-plan {
          padding: 3% 2%;
          font-size: 25px;
          font-weight: 700;
          line-height: 21px;
        }
      }
      .btn-second {
        padding: 3% 5%;
        font-size: 60px;
        font-weight: 700;
        line-height: 51px;
        min-width: 0;
        &.btn-quiz {
          font-size: 35px;
          line-height: 30px;
          margin-top: 4%;
          letter-spacing: 1px;
        }
      }
      .btn-yes-no {
        &.language-customization-kr {
          font-size: 35px;
          line-height: 50px;
        }
      }
    }
  }
  .mobile-hide {
    display: none;
  }
}
