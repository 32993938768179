.searchBackground {
  background: rgba($color: #1b0e44, $alpha: 0.8);
  padding-top: 120px;
  position: relative;
  min-height: 550px;
}

.slantedDiv.slanted-search {
  padding-bottom: 100px;
  width: 100%;
  height: 100px;
  background-color: #fafafc;
  position: relative;
  top: unset;
  bottom: 30px;
  left: 0;
  transform: skewY(-2deg);
}

.vote-bg-image {
  position: absolute;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-height: 550px;
  z-index: -100;
}

.ballotBackground {
  background: #ccc9d4;
  padding-top: 120px;

  .slantedDiv {
    background-color: #ccc9d4;
  }

  .direction h1 {
    color: #1b0e44;
  }

  .signup-form .form-check label {
    color: #1b0e44;
  }
}

.searchText {
  padding: 0 0 50px 0;
  line-height: 200;
}

.welcome {
  font-size: calc(0.7em + 1vw);
  text-transform: uppercase;
  font-family: "avenir_bold", sans-serif;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.direction {
  h1 {
    font-size: calc(2.5em + 1vw);
    font-family: "avenir_regular", sans-serif;
    color: rgb(255, 255, 255);
  }
}

.padding-bottom {
  padding-bottom: 30px;
}

.slantedDiv {
  padding-bottom: 100px;
  width: 100%;
  height: 100px;
  background-color: #1b0e44;
  position: relative;
  top: 40px;
  left: 0;
  transform: skewY(-2deg);
}

.sampleText {
  h2 {
    font-size: calc(1.4em + 1vw);
    font-family: "avenir_demi", sans-serif;
    color: rgba(25, 57, 70, 1);
    font-weight: bold;
    padding: 120px 20px 0 20px;
    text-align: left;
  }
}

.alert-warning {
  margin-top: 10px;
}

.address-input {
  background: #f5f5f5;
  border: 2px solid #193946;
  font-family: "garage-gothic", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  text-transform: uppercase;
  color: #193946;
  outline: none;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
  padding: 0 10px;
  line-height: 53px;
  @media (max-width: 991px) {
    line-height: 45px;
  }
}

.button-submit {
  border-radius: 0 !important;
  width: 100%;
  color: #fff;
  border: none;
  background: transparent;
  background: #ec704c;
  line-height: 34px;
  padding: 8px 5px 8px 12px;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-family: "garage-gothic", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 40px;
    margin: 5px;
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    line-height: 26px;
  }
}

.button-submit:hover {
  background: #17bebb;
}

@media (max-width: 768px) {
  .address-input {
    font-size: 20px;
    padding: 7px 10px;
    line-height: 26px;
  }
  .searchBackground,
  .ballotBackground {
    padding-top: 75px;
    min-height: 450px;

    .direction {
      h1 {
        font-size: calc(1.4em + 1vw);
        font-family: "avenir_regular", sans-serif;
      }
    }

    .searchText {
      padding: 25px 0 40px 0;
    }

    .slantedDiv {
      top: 20px;
      padding-bottom: 50px;
      height: 50px;
    }
  }

  .vote-bg-image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    max-height: 450px;
  }

  .sampleText {
    h2 {
      font-size: calc(1em + 1vw);
      padding: 60px 0 0;
    }
  }
}

@media (max-width: 556px) {
  .searchBackground {
    padding-top: 50px;
    min-height: 350px;
  }

  .vote-bg-image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    max-height: 350px;
    height: 350px;
  }

  .slantedDiv.slanted-search {
    padding-bottom: 50px;
    height: 50px;
    bottom: 30px;
  }
}
