.autocomplete-dropdown-container {
  border: 2px solid #193946;
  border-width: 0px 2px 2px 2px;
  text-align: left;
  span {
    font-family: "garage-gothic", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    text-transform: uppercase;
    color: #193946;
    padding: 2px 10px;
  }
}

@media (max-width: 768px) {
  .autocomplete-dropdown-container span {
    font-size: 15px;
  }
}
