/* Position and sizing of burger button */
.menu-container {
  background: #1b0e44;
  position: relative;
  z-index: 3;
  height: 0px;

  .menu-bg {
    background-color: #fafafc;
    box-shadow: 0px 0px 14px -14px rgba(0, 0, 0, 0.44);
    border-radius: 0 5px 5px 0;
    height: 40px;
    position: absolute;
    width: 33%;
    max-width: 150px;
    cursor: inherit;
  }

  .bg-container {
    display: flex;
    align-items: center;
    height: 40px;
    max-width: 150px;

    .menu-text {
      font-size: 18px;
      font-family: "avenir_bold";
      font-weight: 600;
      color: #1b0e44;
      text-transform: uppercase;
      position: relative;
      left: 50px;
      float: right;
      text-align: right;
      cursor: inherit;
    }
  }

  .bm-burger-button {
    position: relative;
    width: 25px;
    height: 20px;
    left: 36px;
    margin-left: 50%;
    color: white;
    float: right;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: rgb(15, 25, 87);
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background-color: #f02d3a;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 25px;
    width: 25px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #1b0e44;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    margin-top: -10px;
    .menu-item {
      font-size: 24px;
      font-family: "avenir_bold";
      font-weight: 600;
      color: rgb(254, 245, 239);
      margin: 10px 0;
    }

    .colored-item {
      color: #f02d3a;
    }

    .lang-button {
      font-size: 24px;
      font-family: "avenir_bold";
      font-weight: 600;
      color: rgb(254, 245, 239);
      border-radius: 0;
      background-color: #f02d3a;
      border: none;
      box-shadow: none;
      outline: none;
    }

    button.lang-button:hover {
      background-color: #f02d3a;
    }

    button.lang-button:active,
    button.lang-button:focus {
      background-color: #f02d3a;
      border: none;
      box-shadow: none !important;
      outline: none;
    }
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #f02d3a;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: #1b0e44 !important;
    margin-top: -20px;
  }
}

.menu-mobile {
  .menu-bg {
    // position: relative;
    height: 47px;
    max-width: 70px;
    right: 0;
    float: right;
    border-radius: 0;
  }
  .bm-burger-button {
    position: absolute;
    left: unset;
    right: 20px;
    top: 15px;
  }
  .bm-menu {
    margin-top: -20px;
    .menu-item {
      font-size: 12pt;
    }
    .lang-button {
      font-size: 12pt;
    }
  }
}

.info-hub-burger {
  background: #fafafc;
}
