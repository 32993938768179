.ballot-curing {
  p, .text-content {
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #193946;
  }
  a {
    font-weight: bold;
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-style: normal;
    color: #193946;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
    color: #193946;
  }
  .title {
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    text-align: left;
    text-transform: uppercase;
    color: #193946;
    margin: 30px 0px;
  }
  .div-warning .icon-warning {
    top: 8px;
  }
  .warning-title {
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 120%;
  }

  ul {
      list-style-type: '- ';
      margin-bottom:0px;
      li {
        padding: 10px 0px 20px;
      }
  }

  .text-content {
    display: inline-block;
    width: 100%;
    font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #193946;
    margin-bottom: 3%;

    .text-content-note-t {
      float: left;
      width: 90%;
      a {
        text-decoration: underline;
        color: inherit;
      }
      a:hover {
        text-decoration: none;
      }
    }
  }
  .bc-section{
    max-width: none;
    border-top: 5px solid #17bebb;
    padding: 30px 0px;
    justify-content: center;
    display: flex;
    .row {
      margin: 0 auto;
      max-width: 90%;
      .col-left {
        padding-right: 5%;
      }
      .col-right {
        padding-top: 20px;
        padding-left: 5%;
        img {
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }
    .star-warning {
      display: flex;
      align-items: flex-start;
      gap: 20px;
    }
    .icon-star-warning {
      object-fit: contain;
    }

  }
  .flex {
    display: flex;
  }
}

@media (max-width: 768px) {
  .ballot-curing {
    p, .text-content {
      font-size: 15px;
      line-height: 18px;
    }
    .title {
      font-size: 22px;
      margin: 0px 0px 15px;
    }
    .div-warning .icon-warning {
      top: 3px;
    }
    .div-warning .warning-title {
      margin-top: 0px;
      margin-bottom: 12px;
    }
    ul {
      li {
        padding: 0px 0px 10px;
      }
    }

    .bc-section{
      padding: 30px 0px 0px;
      .row {
        .col-left {
          padding: 0px 0px 10px;
        }
        .col-right {
          padding: 0px;
          img {
            margin-bottom: 12px;
          }
        }
      }
      .star-warning {
        gap: 6px;
      }
      .icon-star-warning {
        width: 17px;
      }
    }
  }
}
