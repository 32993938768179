.select-language {
  font-family: "garage-gothic", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22.5px;
  line-height: 36px;
  letter-spacing: 0.05em;
  color: #193946;

  select {
    border: none;
    text-transform: uppercase;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    color: #193946;
  }

  select:focus {
    outline: none;
  }

  select::-ms-expand {
    display: none;
  }
}

@media (max-width: 768px) {
  select {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
  }
}
