.ctn-register-tovote {
  padding: 20px 0px;

  .title {
    font-weight: 700;
    font-size: 200px;
    line-height: 170px;
    letter-spacing: -0.02em;
    color: #193946;
    text-transform: uppercase;
    text-align: center;
  }

  .leader {
    font-weight: 700;
    font-size: 40px;
    line-height: 34px;
    letter-spacing: -0.02em;
    color: #193946;
    text-align: center;
    margin: 20px;
  }

  .cast-vote {
    padding: 10px 10%;
    text-align: center;

    .btn-start {
      font-size: 147px;
      line-height: 124px;
      font-weight: 700;
      font-family: "garage-gothic", sans-serif;
      background-color: #ec704c;
      color: rgba(25, 57, 70, 1);
      text-transform: uppercase;
      padding: 5% 15%;
      outline: none;
      border: none;
      letter-spacing: -0.02em;
      &.btn-plan {
        height: 100%;
      }
    }

    .btn-start:hover {
      background: #17bebb;
    }

    .btn-small {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 31px;
      letter-spacing: -0.02em;
      width: 100%;
      margin: 5px 0;
      padding: 5% 2%;
    }
  }

  .register-to-vote {
    width: 90%;
    border-top: 5px solid #17bebb;
    margin: 20px auto;
    text-align: center;
    .description {
      font-family: "haboro-soft", "Gill Sans MT", Calibri, "Trebuchet MS",
        sans-serif;
      font-size: 25px;
      line-height: 27.5px;
      font-weight: 500;
      letter-spacing: -0.02em;
      margin: 30px;
      text-align: center;
    }
    .btn-register {
      font-size: 4.5vw;
      line-height: 4.5vw;
      font-weight: 700;
      font-family: "garage-gothic", sans-serif;
      background-color: #ec704c;
      color: rgba(25, 57, 70, 1);
      text-transform: uppercase;
      padding: 5% 25%;
      outline: none;
      border: none;
      padding: 10px 5%;
    }
    .btn-register:hover {
      background: #17bebb;
    }
  }
}

@media (max-width: 1200px) {
  .ctn-register-tovote {
    .title {
      font-size: 150px;
      line-height: 112px;
    }
    // .btn-register {
    // 	font-size: px;
    // 	line-height: 80px;
    // }
  }
}

@media (max-width: 768px) {
  .ctn-register-tovote {
    padding-bottom: 0;
    .title {
      font-size: 85px;
      line-height: 66px;
    }
    .leader {
      font-size: 25px;
      line-height: 25px;
      margin: 0;
    }
    .cast-vote {
      padding: 10px 5%;

      .cast-vote-item {
        width: 100%;
        margin: 10px 0;
      }
    }
    .btn-register {
      font-size: 7vw !important;
      line-height: 7vw !important;
    }
  }
}
